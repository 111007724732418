import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as appActions from 'Actions/app';
import * as eventActions from 'Actions/events'
import * as lessonActions from 'Actions/lesson';
import { useEffect, useState, useRef, useCallback } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { PlayContainer } from 'Components/RoadmapComponents/PlayContainer';
import { Minimap } from 'Components/RoadmapComponents/Minimap';
import { Menu } from 'Components/RoadmapComponents/Menu';
import { Path } from 'Components/RoadmapComponents/Path';
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { MainAppReducer } from 'Types';
import { getHighestCompletedLevel, getUnitPctComplete } from 'Utils/UserProgress'
import { CustomCircularProgress } from 'Components/StyledComponents'
import Survey from './Survey';
import './Roadmap.css'
import { useLocation } from 'react-router-dom'
import { useComponentWillUnmount } from 'Utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isSafariBrowser, isMobileBrowser, isCompatibleAndroid } from 'Utils/UserAgent';
import { SafariBlockingScreen, MobileBlockingScreen } from 'Containers/SafariBlockingScreen';
import { AuthReducer } from 'Types/AuthTypes';
import { isPhoneNumberWithCountryCode } from 'Components/Forms/SignUpForm';
import { MIN_NUM_PHRASES } from 'Utils/Constants';
import { awsRum } from 'Utils/AwsRum';


const Roadmap = () => {
  const navigate = useNavigate();
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const userPhoneNumber = auth.user?.attributes?.phone_number

  // We will assess their latest place in the level based on what is shown in the userLevelData
  const {
    levelData,
    levelSelect,
    userLevelData,
    tiersByLevels,
    unitSelect,
    userData,
  } = data
  let location = useLocation();

  const [areaToPreventScroll, setAreaToPreventScroll] = useState('fuck!')
  const [scrollPos, setScrollPos] = useState(69)
  const [scrollAreaSize, setScrollAreaSize] = useState(.5)
  const dispatch = useDispatch();
  const [pathScrollPos, setPathScrollPos] = useState(0)
  const roadmapRef: any = useRef(null)
  const latestLevelRef: any = useRef(null)
  const [cursorInRoadmap, setCursorInRoadmap] = useState(false)


  useEffect(() => {
    // redirect user back to welcome so they can't access roadmap yet
    if (userData && !userData.subscription_status) {
      navigate('/welcome')
    }
  }, [userData])

  const [highestCompleteData, setHighestCompleteData] = useState(getHighestCompletedLevel(userLevelData));
  useEffect(() => {
    setHighestCompleteData(getHighestCompletedLevel(userLevelData));
  }, [userLevelData]);

  useEffect(() => {
    // This prevents scroll. (Must prevent default action to stop navigate back)
    const preventNavigation = (e: any) => {
      if (roadmapRef.current && !cursorInRoadmap) {
        e.preventDefault();
      }
    }
    if (roadmapRef.current) {
      roadmapRef.current.addEventListener('wheel', preventNavigation, { passive: false });
      return () => {
        if (roadmapRef.current) {
          roadmapRef.current.removeEventListener('wheel', preventNavigation);
        }
      }
    }
  }, [cursorInRoadmap])

  const handleMouseMove = (e: any) => {
    // We must know if cursor is inside of scroll (we will use to prevent swipe to navigate back)
    if (latestLevelRef.current) {
      const boxRect = latestLevelRef.current.getBoundingClientRect();
      const isInside =  e.clientX >= boxRect.left && e.clientX <= boxRect.right && e.clientY >= boxRect.top && e.clientY <= boxRect.bottom;
      if (isInside) {
        setCursorInRoadmap(true)
      } else {
        setCursorInRoadmap(false)
      }
    }
  };


  const handleUnitSelect = useCallback((unit:number)=>{
    dispatch(appActions.setUnitSelect({unitSelect:unit}))
  },[dispatch, auth.jwtToken])

  // Need to check on roadmap independently
  const matchesHeight = useMediaQuery('(min-width:1000px)');
  const matchesWidth = useMediaQuery('(min-height:1000px)');
  if (isMobileBrowser() && !isCompatibleAndroid() && !(matchesHeight || matchesWidth)) {
    return <MobileBlockingScreen/>
  }
  if (isSafariBrowser()) {
    return <SafariBlockingScreen/>
  }
  
    let selectedLevel = levelData[levelSelect] || levelData[0]
    let level_number = selectedLevel.level_number
    let name = selectedLevel.name
    let description = selectedLevel.description
    
    let lastLevelComplete = highestCompleteData.lastLevelComplete
    let userLevelStatuses  = highestCompleteData.userLevelStatuses
    let userLevelTiersComplete = highestCompleteData.userLevelTiersComplete
    let selectedLevelProgress
    userLevelData.forEach(ul => {
      if (ul?.level?.level_number == level_number) {
        selectedLevelProgress = ul
      }
    })
    let unitPercentComplete = getUnitPctComplete(lastLevelComplete, levelData) // This will come from state as well
    let tierByLevel = tiersByLevels ? tiersByLevels.find(item => item.level_number === level_number) : undefined
    let tierCountForLevel = tierByLevel?.tiers?.length ||  1
  
    return (
      <Box 
        sx={{
          display: 'flex', 
          height: '100vh', 
          width: '100%',
        }}
        ref={roadmapRef}
        onMouseMove={handleMouseMove}
        >
        <Menu selected={"Roadmap"}/>
        <Survey />

        <Box sx={{
          // (100px is width of menu bar) potentially should redo styles of menu container; but makes it take the full width of the screen without scrolling right when shrunk
          width: 'calc(100% - 100px)', backgroundColor: 'black'}}>
        <Box sx={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          background: 'linear-gradient(0deg, black, #1D2F44)',
          height: '100%',
        }}>
          <Box sx={{
            width: '100%', 
            height: '100px', 
            minHeight: '100px',
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            paddingBottom: '20px',
          }}>
            <Box sx={{
              marginLeft: '50px',
              //fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '48px',
              lineHeight: '58px',
              letterSpacing: '0.01em',
              color: '#FFFFFF',
            }}>

            Level Roadmap
            </Box>
            <Box sx={{marginRight: '50px'}}>
            <Minimap 
              unitPercentComplete={unitPercentComplete} 
              scrollPosFromRoadmap={scrollPos} 
              setScrollPosFromRoadmap={setScrollPos}
              scrollAreaSize={scrollAreaSize}
              areaToPreventScroll={areaToPreventScroll}
              setAreaToPreventScroll={setAreaToPreventScroll}
              setPathScrollPos={setPathScrollPos}
            />
            </Box>
          </Box>
          {(process.env.REACT_APP_NODE_ENV === 'local' || process.env.REACT_APP_NODE_ENV === 'dev') && 
          <Box
            sx={{
              alignSelf: 'baseline',
              color:'white',
              backgroundColor:'#1D2F44',
              '& .Mui-focused': {
                color: '#fff'
              },
              marginLeft: '50px',
            }}
          >
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth
                sx={{ 
                  color: '#fff',
                  svg: {fill: '#fff'},
                  '&.Mui-focused': {
                    color: '#fff',
                    border: 0
                  },
                }}
              >
                <InputLabel id="unit-select-label" 
                  sx={{ 
                    color: '#fff',
                    outline: 'none',
                    '&.Mui-focused': {
                      color: '#fff',
                    },
                  }}
                >Unit</InputLabel>
                <Select
                  labelId="unit-select-label"
                  id="unit-select"
                  value={unitSelect.toString()}
                  label="Unit"
                  onChange={(evt: SelectChangeEvent)=>{
                    handleUnitSelect(parseInt(evt.target.value))
                  }}
                  sx={{ 
                    color: '#fff',
                    svg: {fill: '#fff'},
                    '&.Mui-focused': {
                      color: '#fff',
                      border: 0
                    },
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          }
          <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <Box className="roadmap-container-styles">
              <Path 
                levelSelect={levelSelect}
                unitSelect={unitSelect}
                levelData={levelData}
                userLevelData={userLevelData}
                lastLevelComplete={lastLevelComplete} 
                userLevelStatuses={userLevelStatuses} 
                userLevelTiersComplete={userLevelTiersComplete}
                
                scrollPos={scrollPos} 
                setScrollPos={setScrollPos} 
                setScrollAreaSize={setScrollAreaSize}
                areaToPreventScroll={areaToPreventScroll}
                setAreaToPreventScroll={setAreaToPreventScroll}
                pathScrollPos={pathScrollPos}
                latestLevelRef={latestLevelRef}
              />
            </Box>
          <Box sx={{margin: '40px auto 40px auto', maxWidth: '100%'}}>
            <PlayContainer selectedLevel={selectedLevel} levelSelect={levelSelect} level_number={level_number} name={name} description={description} selectedLevelProgress={selectedLevelProgress} tierCountForLevel={tierCountForLevel}/>
          </Box>
          </Box>
        </Box>
        </Box>
      </Box>
    )
 
}

export default Roadmap
