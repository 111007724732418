import { OpenSheetMusicDisplay as OSMD, GraphicalNote, VexFlowGraphicalNote, Note } from 'opensheetmusicdisplay';
import { PrerenderedGraphics, MidiEventState, midiSemiErrorStates, midiFullErrorStates, MidiEventType, ErrorRecognitionActive } from 'Types';
import { makeNoteSemiError, makeNoteError, makeNoteCorrect, resetNote } from 'Utils/OSMDUtility';
import { VoiceEntry } from 'opensheetmusicdisplay';
import ITimeKeeper from './ITimeKeeper';
import { findAndReplace, isErrorState, isSemiErrorState, sumToN } from 'Utils';
import { sum, range, every, find, rest } from 'lodash';
import { v4 as uuid } from 'uuid';
import Phrase, {Iterator, IteratorPhaser} from './Phrase';
import { convertToObject, isConstructorDeclaration } from 'typescript';
import { COLORBLIND_TYPE, ERROR_REC_CLASS, HANDEDNESS } from 'Utils/Constants';
import logger from 'Utils/Logger';
import { getColorByCbType } from 'Components/Colors';

const onsetDefault = 0.3
const offsetDefault = 0.3


// All timing configuration settings are percentages and relative to the length of the note/rest being played.
// For instance, a quarter note with a correct start onset allowance of 0.15 will be considered in the green zone
// as long as the MIDI onset event is received within 15% of the quarter note's duration at the quarter note's expected onset.
export class TimingOffsetsConfig {
    // Left margin percentage around a note's onset for the correct or green zone.
    correctNoteOnsetStartPct = onsetDefault * .5
    // Right margin percentage around a note's onset for the correct or green zone.
    correctNoteOnsetEndPct = .2
    // Left margin percentage around a note's onset for the mistimed or yellow zone.
    mistimedNoteOnsetStartPct = .35
    // Right margin percentage around a note's onset for the mistimed or yellow zone.
    mistimedNoteOnsetEndPct = 0.6
    
    // Left margin percentage around a note's offset for the correct or green zone.
    correctNoteOffsetStartPct = offsetDefault
    // Right margin percentage around a note's offset for the correct or green zone.
    correctNoteOffsetEndPct = offsetDefault
    // Left margin percentage around a note's offset for the mistimed or yellow zone.
    mistimedNoteOffsetStartPct = 0.35
    // Right margin percentage around a note's offset for the mistimed or yellow zone.
    mistimedNoteOffsetEndPct = 0.25
    
    // The margin percentaes of rests SHRINK their bucket rather than growing it.

    // Left margin correct percentage around the start of the rest.
    // The correct zone shrinks "inward" (past the rest's onset) by this percentage.
    correctRestOnsetPct = 0.1
    // Left margin mistimed percentage around the start of the rest.
    // The mistimed zone shrinks "inward" (past the rest's onset) by this percentage.
    mistimedRestOnsetPct = 0.2
    // Right margin correcct percentage around the end of the rest.
    // The correct zone shrinks "inward" (before the rest's offset) by this percentage.
    correctRestOffsetPct = 0.25
    // Right margin mistimed percentage around the end of the rest.
    // The mistimed zone shrinks "inward" (before the rest's offset) by this percentage.
    mistimedRestOffsetPct = 0.25

}

// Timing offsets are relative to the expected timing for note on/off events.
export class TimingOffsets {
    // The max allowable timestamp ahead of the expected event time
    // for an event to be considered correctly timed in the correct or in the "green zone".
    correctStartTimestamp: number
    // The max allowable timestamp after the expected event time
    // for an event to be considered correctly timed in the correct or in the "green zone".
    correctEndTimestamp: number
    // The max allowable timestamp ahead of the expected event time
    // for an event to be considered mistimed or in the "yellow zone".
    mistimedStartTimestamp: number
    // The max allowable timestamp after the expected event time
    // for an event to be considered mistimed or in the "yellow zone".
    mistimedEndTimestamp: number

    constructor(correctStartTimestamp: number, correctEndTimestamp: number, mistimedStartTimestamp: number, mistimedEndTimestamp: number) {
        this.correctStartTimestamp = correctStartTimestamp
        this.correctEndTimestamp = correctEndTimestamp
        this.mistimedStartTimestamp = mistimedStartTimestamp
        this.mistimedEndTimestamp = mistimedEndTimestamp
    }
}

// Encapsulates everything we need to know about a single note or rest to match MIDI events to it.
// References OSMD graphical notes so we can handle coloring.
export class NoteEvent {
    length: number
    timestamp: number
    midiNote: number
    noteOnTimingOffsets: TimingOffsets
    noteOffTimingOffsets: TimingOffsets
    graphics: PrerenderedGraphics[]
    protected onsetState: MidiEventState = MidiEventState.UNSET
    protected offsetState: MidiEventState = MidiEventState.UNSET
    phraseRef?: Phrase
    additionalNote?: number
    hand: HANDEDNESS
    // snapshotPhraseAccuracy is a saved accuracy calculation directly after the note is pressed, or unpressed. 
    // This allows us to record the accuracy change caused by any particular note
    snapshotPhraseAccuracy?: number = undefined
    LhSnapshotPhraseAccuracy?: number = undefined
    RhSnapshotPhraseAccuracy?: number = undefined

    constructor(
        timestamp: number, 
        midiNote: number,
        noteOnTimingOffsets: TimingOffsets, 
        noteOffTimingOffsets: TimingOffsets,
        graphics: PrerenderedGraphics[],
        length: number,
        phraseRef: Phrase | undefined,
        hand: HANDEDNESS
    ) {
        this.timestamp = timestamp
        this.midiNote = midiNote
        this.noteOnTimingOffsets = noteOnTimingOffsets
        this.noteOffTimingOffsets = noteOffTimingOffsets
        this.graphics = graphics
        this.length = length
        this.phraseRef = phraseRef
        this.hand = hand
    }

    public setOnsetState(eventState: MidiEventState) {
      this.onsetState = eventState
    }

    public setOffsetState(eventState: MidiEventState) {
      this.offsetState = eventState
    }

    public setAdditionalNote(noteMidi: number) {
        this.additionalNote = noteMidi;
    }

    public setSnapshotPhraseAccuracy(accuracy: number) {
      this.snapshotPhraseAccuracy = accuracy
    }

    public setRhSnapshotPhraseAccuracy(accuracy: number) {
      this.RhSnapshotPhraseAccuracy = accuracy
    }

    public setLhSnapshotPhraseAccuracy(accuracy: number) {
      this.LhSnapshotPhraseAccuracy = accuracy
    }

    public getOnsetState(): MidiEventState {
      return this.onsetState
    }

    public getOffsetState(): MidiEventState {
      return this.offsetState
    }
    
    public getSnapshotPhraseAccuracy() {
      return this.snapshotPhraseAccuracy || 0
    }

    public getLhSnapshotPhraseAccuracy() {
      return this.LhSnapshotPhraseAccuracy || 0
    }

    public getRhSnapshotPhraseAccuracy() {
      return this.RhSnapshotPhraseAccuracy || 0
    }
}

export class RestEvent {
    length: number
    timestamp: number
    // Rests only have one set of offsets since they have no associated on/off MIDI events.
    timingOffsets: TimingOffsets
    state: MidiEventState = MidiEventState.UNSET
    graphics: PrerenderedGraphics[]
    hidden: boolean
    additionalNote?: number
    hand: HANDEDNESS
    snapshotPhraseAccuracy?: number = undefined
    LhSnapshotPhraseAccuracy?: number = undefined
    RhSnapshotPhraseAccuracy?: number = undefined 

    constructor(timestamp: number, timingOffsets: TimingOffsets, graphics: PrerenderedGraphics[], length: number, hidden: boolean, hand: HANDEDNESS) {
        this.timestamp = timestamp
        this.timingOffsets = timingOffsets
        this.graphics = graphics
        this.length = length
        this.hidden = hidden
        this.hand = hand
    }

    public setAdditionalNote(noteMidi: number) {
        this.additionalNote = noteMidi;
    }

    public setSnapshotPhraseAccuracy(accuracy: number) {
      this.snapshotPhraseAccuracy = accuracy
    }

    public setRhSnapshotPhraseAccuracy(accuracy: number) {
      this.RhSnapshotPhraseAccuracy = accuracy
    }

    public setLhSnapshotPhraseAccuracy(accuracy: number) {
      this.LhSnapshotPhraseAccuracy = accuracy
    }

    public getSnapshotPhraseAccuracy() {
      // potentially return undefined in order to know whether the rest was played or not
      return this.snapshotPhraseAccuracy 
    }

    public getLhSnapshotPhraseAccuracy() {
      return this.LhSnapshotPhraseAccuracy
    }

    public getRhSnapshotPhraseAccuracy() {
      return this.RhSnapshotPhraseAccuracy
    }
}

export class EventBucket {
    // Any audible notes that are played at this timestamp.
    notes: NoteEvent[]

    // We process rests differently from notes;
    // they get their own list to keep things cleaner.
    rests: RestEvent[]

    // The exact number of timestamp that events in this bucket onset at.
    // Per-note timing offsets are added and subtracted from this number.
    timestamp: number

    // The earliest timestamp that a MIDI event could match on a note/rest in this bucket.
    // Before this time has passed, there's no reason to look inside this bucket when matching MIDI note ON events.
    minOnsetTimestamp: number = -1

    // The latest timestamp that a MIDI event could match on a note/rest in this bucket.
    // After this time has passed, there's no reason to look inside this bucket when matching MIDI note ON events.
    maxOnsetTimestamp: number = -1

    // After this timestamp has passed and we aren't waiting for any more offset events,
    // the playback data from this bucket can be uploaded to the backend.
    maxOffsetTimestamp: number = -1

    // PhraseRef provides a link back to the phrase associated with the bucket
    phraseRef?: Phrase

    constructor(timestamp: number, notes: NoteEvent[], rests: RestEvent[], phraseRef: Phrase | undefined) {
        this.timestamp = timestamp
        this.notes = notes
        this.rests = rests
        this.phraseRef = phraseRef

        this.minOnsetTimestamp = 0
        this.maxOnsetTimestamp = 0
        this.maxOffsetTimestamp = 0
        for (let note of notes) {
            if(note.noteOnTimingOffsets.mistimedStartTimestamp < this.minOnsetTimestamp ) {
                this.minOnsetTimestamp = note.noteOnTimingOffsets.mistimedStartTimestamp
            }
            // will probably need to revisit this
            if(note.noteOnTimingOffsets.mistimedEndTimestamp > this.maxOnsetTimestamp ) {
                this.maxOnsetTimestamp = note.noteOnTimingOffsets.mistimedEndTimestamp
            }
            if(note.noteOnTimingOffsets.mistimedEndTimestamp > this.maxOffsetTimestamp ) {
                this.maxOffsetTimestamp = note.noteOnTimingOffsets.mistimedEndTimestamp
            }
        }
    }

    addEvents(notes: NoteEvent[], rests: RestEvent[]) {
        this.notes.push(...notes)
        this.rests.push(...rests)
    }

    sort() {
        this.notes.sort((n1, n2) => n1.noteOffTimingOffsets.mistimedEndTimestamp - n2.noteOffTimingOffsets.mistimedEndTimestamp);
        this.rests.sort((r1, r2) => r1.timingOffsets.correctEndTimestamp - r2.timingOffsets.correctEndTimestamp)

        this.minOnsetTimestamp = Math.min(
            ...this.notes.map(n => n.noteOnTimingOffsets.mistimedStartTimestamp),
            ...this.rests.map(r => r.timingOffsets.correctStartTimestamp)
        )
        this.maxOnsetTimestamp = Math.max(
            ...this.notes.map(n => n.noteOnTimingOffsets.mistimedEndTimestamp),
            ...this.rests.map(r => r.timingOffsets.mistimedEndTimestamp)
        )
        this.maxOffsetTimestamp = Math.max(
            ...this.notes.map(n => n.noteOffTimingOffsets.mistimedEndTimestamp),
            ...this.rests.map(r => r.timingOffsets.correctEndTimestamp)
        )
    }
}


export default class EventStream {
    timeKeeper: ITimeKeeper
    private offsetsConfig: TimingOffsetsConfig
    private eventBuckets: EventBucket[] = []
    private phraseHistory: Phrase[] = []
    private activeOnsetEvents: (NoteEvent | null)[]
    private lastProcessedTimestamp: number = -1
    private onsetIndex: number = 0
    private missedNotesIndex: number = 0
    private _lookbackWindowSize = 40;
    private _weightedAccuracyDivisions: number;
    private _weightedAccuracyHitVelocity: number;
    private _weightedAccuracyMissVelocity: number;

    private correctNoteMagnitude: number = 1
    private offNoteMagnitude: number = .8
    private missMagnitude: number = .3
    accuracyQueue: MidiEventState[] = [];
    lhAccuracyQueue: MidiEventState[] = [];
    rhAccuracyQueue: MidiEventState[] = [];
    id = uuid();
    private useRepertoireMarkAccuracy: boolean;
    public errorRecognitionActive: ErrorRecognitionActive = {left: true, right: true}
    // while this seems more UI related than midi-event processing related, the eventstream changes the color
    // of notes based on correctness, so must have access to that information, but doesn't have access to redux.
    private colorblindType: COLORBLIND_TYPE | null


    constructor(timeKeeper: ITimeKeeper, offsetsConfig: TimingOffsetsConfig, colorblindType: COLORBLIND_TYPE | null, weightedAccuracyDivisions: number = 1, weightedAccuracyHitVelocity: number = 0.025, weightedAccuracyMissVelocity:number = .1, useRepertoireMarkAccuracy: boolean = false) {
        this.timeKeeper = timeKeeper;
        this.offsetsConfig = offsetsConfig
        this._weightedAccuracyDivisions = weightedAccuracyDivisions;
        this._weightedAccuracyHitVelocity = weightedAccuracyHitVelocity;
        this._weightedAccuracyMissVelocity = weightedAccuracyMissVelocity;
        this.useRepertoireMarkAccuracy = useRepertoireMarkAccuracy; 
        this.colorblindType = colorblindType
        if (this.useRepertoireMarkAccuracy) {
          this._lookbackWindowSize = 0;
        }

        this.activeOnsetEvents = []
        this.setupOnsetEvents()
        this.initAccuracyQueues()
        
    }

    // workaround because the midi context loses reference to state. So, turn off error recognition here.
    setErrorRecognitionActive(errorRecognitionActive: ErrorRecognitionActive) {
        this.errorRecognitionActive = errorRecognitionActive;
    }


    initAccuracyQueues = () => {
        this.accuracyQueue = range(this._lookbackWindowSize).map(() => MidiEventState.HIT)
        this.lhAccuracyQueue = range(this._lookbackWindowSize).map(() => MidiEventState.HIT)
        this.rhAccuracyQueue = range(this._lookbackWindowSize).map(() => MidiEventState.HIT)
    }

    accuracyQueueLength = () => this.accuracyQueue.length;

    totalNotesLength = () => {
      let totalNotesSum = 0;
      this.eventBuckets.forEach((el) => totalNotesSum += el.notes.length);
      return totalNotesSum
    };

    rollBackWindowFull = () => this.accuracyQueue.length >= this._lookbackWindowSize;

    // Mark state. If current state is anything but unset, that value is removed from the queue and replaced. 
    private markAccuracy(accuracyQueue: MidiEventState[], currentNoteState: MidiEventState, newNoteState: MidiEventState) { 
      if(currentNoteState === newNoteState) {
        return;
      }
      switch(currentNoteState) {
        case MidiEventState.HIT:
        case MidiEventState.NEVER_PLAYED:
        case MidiEventState.WRONG_NOTE:
        case MidiEventState.EARLY_ONSET:
        case MidiEventState.EARLY_OFFSET:
        case MidiEventState.LATE_ONSET:
        case MidiEventState.LATE_OFFSET:
        case MidiEventState.ADDITIONAL_NOTE:
            // console.log("current note state is", currentNoteState)
            findAndReplace(currentNoteState, newNoteState, accuracyQueue)
            break;
        default:
            // console.log("pushing accuracy")
            accuracyQueue.push(newNoteState)
            if (!this.useRepertoireMarkAccuracy) { // Repertoire does not use lookback window and doesn't need to cut array
              if(accuracyQueue.length > this._lookbackWindowSize) {
                accuracyQueue.shift()
              }
            }
            break;  
        }
    }

    private markAccuracyBothHands(note: NoteEvent | RestEvent, eventState: MidiEventState) {
        if(note instanceof NoteEvent) {
            const noteStateBeforeMark = note.getOnsetState(); // need to save this because it's mutated by markAccuracy
            this.markAccuracy(this.accuracyQueue,noteStateBeforeMark, eventState)
            if(note.hand === 'lh') {
                this.markAccuracy(this.lhAccuracyQueue, noteStateBeforeMark, eventState)
            } else if(note.hand === 'rh') {
                this.markAccuracy(this.rhAccuracyQueue, noteStateBeforeMark, eventState)
            }
        } else {
            this.markAccuracy(this.accuracyQueue, note.state, eventState)
            if(note.hand === 'lh') {
                this.markAccuracy(this.lhAccuracyQueue, note.state, eventState)
            } else if(note.hand === 'rh') {
                this.markAccuracy(this.rhAccuracyQueue, note.state, eventState)
            }
        }

    }

    private makeNoteColor(note: NoteEvent | RestEvent, eventState: MidiEventState) {
        if(eventState === MidiEventState.HIT) {
            makeNoteCorrect(note.graphics, this.colorblindType)
        } else if(midiSemiErrorStates.contains(eventState)) {
            makeNoteSemiError(note.graphics, this.colorblindType)
        } else if(midiFullErrorStates.contains(eventState)) {
            makeNoteError(note.graphics, this.colorblindType)
        }
    }


    // markOffsetAccuracy does not call markAccuracy
    private setOffsetAccuracy(note: NoteEvent, eventState: MidiEventState) {
        this.makeNoteColor(note, eventState)
        note.setOffsetState(eventState)
        note.setSnapshotPhraseAccuracy(this.calcAccuracy())
    }

    // markOffsetAccuracy does not call markAccuracy
    private setOnsetAccuracy(note: NoteEvent, eventState: MidiEventState) {
        this.makeNoteColor(note, eventState)
        note.setOnsetState(eventState)
    }

    private markOnsetAccuracyWithSideEffects(note: NoteEvent, eventState: MidiEventState, pulse: boolean = true, additionalNote?: number) {
        // only pulse on correct. At least that's how the code reads currently.
        if(eventState === MidiEventState.HIT && pulse) {
            this.pulseMatchedNote(note, ERROR_REC_CLASS.CORRECT)
        }
        this.markAccuracyBothHands(note, eventState)
        // this must be called after markAccuracy because the previous onset state is used to mark the accuracy
        this.setOnsetAccuracy(note, eventState)
        if(additionalNote) {
            note.setAdditionalNote(additionalNote);
        }
    }

    private markOffsetAccuracyWithSideEffects(note: NoteEvent, eventState: MidiEventState) {
        this.makeNoteColor(note, eventState)
        this.markAccuracyBothHands(note, eventState)
        note.setOffsetState(eventState)
        note.setLhSnapshotPhraseAccuracy(this.calcLHAccuracy())
        note.setRhSnapshotPhraseAccuracy(this.calcRHAccuracy())
        note.setSnapshotPhraseAccuracy(this.calcAccuracy())
    }

    private markRestAccuracyWithSideEffects(rest: RestEvent, additionalNote: number) {
        this.markAccuracyBothHands(rest, MidiEventState.ADDITIONAL_NOTE)
        makeNoteSemiError(rest.graphics, this.colorblindType);
        rest.state = MidiEventState.ADDITIONAL_NOTE
        // rests are unset by default, so to call this at all implies an additional note
        rest.setAdditionalNote(additionalNote);   
    }

    _constructAccuracyWeightsArr = (): number[] => {
        let weightedAccuracyDivisionsIndx = 0;
        return this.accuracyQueue.map((evt,indx) => {
            if(indx >  ((weightedAccuracyDivisionsIndx + 1) * this._weightedAccuracyDivisions) - 1) {
                weightedAccuracyDivisionsIndx = weightedAccuracyDivisionsIndx + 1;
            }
            if(evt === MidiEventState.HIT) {
                return 1 - (this._weightedAccuracyHitVelocity * weightedAccuracyDivisionsIndx)
            } else if(!isErrorState(evt)) {
                return 1 - (this._weightedAccuracyHitVelocity * weightedAccuracyDivisionsIndx)
            } else {
                return 1 - (this._weightedAccuracyMissVelocity * weightedAccuracyDivisionsIndx)
            }
        })
    }

    calcAccuracyHelper(accuracyQueue: MidiEventState[]) {
        const accuracyReversed = accuracyQueue.slice().reverse()
        const accuracyLength = accuracyReversed.length;
        const numerator = sum(accuracyReversed.map((evt, indx) => {
            let sumTarget
            if(evt === MidiEventState.HIT) {
                // sumTarget = (accuracyLength * this.correctNoteMagnitude) - (accuracyLength - indx) >= 0 ? ((accuracyLength * this.correctNoteMagnitude) - (accuracyLength - indx)) : 0
                return (accuracyLength - indx)
            } else if(!isErrorState(evt)) {
                // start subtracting from top of magnitude... i.e. 40 * .5 is 20 so 20, 19, 18...
                // and when we get to zero 
                sumTarget = (accuracyLength - indx) * this.correctNoteMagnitude >= 0 ? (accuracyLength - indx) * this.correctNoteMagnitude : 0
                return sumTarget * this.offNoteMagnitude
            } else {
                return 0
            }
        }))
        const denominator = sum(accuracyReversed.map((evt, indx) => {
            let sumTarget;

            if(evt === MidiEventState.HIT) {
                // sumTarget = (accuracyLength * this.correctNoteMagnitude) - (accuracyLength - indx) >= 0 ? ((accuracyLength * this.correctNoteMagnitude) - (accuracyLength - indx)) : 0
                return (accuracyLength - indx)
            } else if(!isErrorState(evt)) {
                // start subtracting from top of magnitude... i.e. 40 * .5 is 20 so 20, 19, 18...
                // and when we get to zero 
                sumTarget = (accuracyLength - indx) * this.correctNoteMagnitude >= 0 ? (accuracyLength - indx) * this.correctNoteMagnitude : 0
                return sumTarget
            } else {
                // same same but different
                sumTarget = (accuracyLength - indx) * this.missMagnitude >= 0 ? (accuracyLength - indx) * this.missMagnitude : 0
                return sumTarget
            }
        }))
        // console.log("returning accuracy " + (numerator / denominator) * 100)
        return (numerator / denominator) * 100
    }

    

    calcAccuracy() {
        // console.log("accuracy queue: ", this.accuracyQueue)
        const acc =  this.calcAccuracyHelper(this.accuracyQueue);
        // console.log("accuracy=", acc)
        // console.log(this.accuracyQueue)
        return acc;
    }

    calcLHAccuracy() {
        // console.log("lh accuracy queue: ", this.lhAccuracyQueue)
        const lhAccuracy = this.calcAccuracyHelper( this.lhAccuracyQueue);
        // console.log("lh accuracy", lhAccuracy)
        return lhAccuracy
    }

    calcRHAccuracy() {
        // console.log("rh accuracy queue ", this.rhAccuracyQueue)
        const rhAccuracy = this.calcAccuracyHelper(this.rhAccuracyQueue);
        // console.log("rh accuracy", rhAccuracy)
        return rhAccuracy;
    }


    calcRepAccuracyAndCompletion(tempo: number, performanceTempo: number, setAccuracyAtTempo: React.Dispatch<React.SetStateAction<number[]>>) {
      // I had to bring this logic of "setAccuracyAtTempo" inside the midi stream, because in the context
      // "midiStream.accuracyQueue" does not update unless some other action causes re-render
      //  (you can observe by logging "midiStream.accuracyQueue" in the RepertoirePlayContext vs logging "this.accuracyQueue" here in EventStream)
    
      const accuracyLength = this.accuracyQueue.length;
        const accuracyReversed = this.accuracyQueue.slice().reverse()
        const numerator = sum(accuracyReversed.map((evt, indx) => {
            if(evt === MidiEventState.HIT) {
                return 1
            } else if(!isErrorState(evt)) {
                return 0.50
            } else {
                return 0
            }
        }))
        const denominator = accuracyLength
        let newVal = this.accuracyQueue.map((v) => {
          let performanceFac = Math.min(tempo/performanceTempo, 1)
          if(isErrorState(v)) {
            return 0*performanceFac
          } else if (isSemiErrorState(v)) {
            return 0.5*performanceFac
          } else {
            return 1.0*performanceFac
          }
        })
        setAccuracyAtTempo(newVal);
        return (numerator / denominator) * 100
    }

    private setupOnsetEvents() {
        // There are 128 MIDI event pitches.
        // Allocate a fixed size array with 128 slots,
        // and incoming MIDI on/off events will be "mapped" using their pitch into this array.
        this.activeOnsetEvents = new Array(128)
        this.activeOnsetEvents.fill(null)
        // See: https://stackoverflow.com/a/44853951
        Object.seal(this.activeOnsetEvents)

        let str = "" 
        for (let i = 0; i <= 24; i++) {
            const x = (1/24) * i
            str = str + `\n${4*x}, ${4*this.calculateCorrectNoteOffMin(x)}`
        }
    }

    reset() {
        this.eventBuckets = []
        this.lastProcessedTimestamp = -1
        this.onsetIndex = 0
        this.missedNotesIndex = 0
        this.setupOnsetEvents()
        this.initAccuracyQueues()
        // this.accuracyQueue = []
    }

    addEvents2(iterator: Iterator | IteratorPhaser, startMeasure: number) {
        iterator.resetIterator()

        while(!iterator.EndReached) {

            const notes: NoteEvent[] = []
            const rests: RestEvent[] = []
            const onsetTimestamp = startMeasure + iterator.currentTimeStamp

            iterator.CurrentVoiceEntries.forEach(currentVoiceEntry => {
                for (const note of currentVoiceEntry.Notes) {
                    // todo: figure out how to do note-tie in prerendering 
                    const noteDuration = note.length
                    // calculateTiedNoteLength() will return a negative value 
                    // if a tied note should be discarded for event stream purposes.
                    // This is now performed in the prerenderer
                    if (noteDuration <= 0) {
                        return
                    }                    
                    const offsetTimestamp = onsetTimestamp + noteDuration
                    const correctNoteOffsetStartTimestamp = onsetTimestamp + this.calculateCorrectNoteOffMin(noteDuration)
    
                    const startAllowance = 0.25
                    const endAllowance = 0.25
    
                    if (note.isRest) {
                        const restEvent = new RestEvent(
                            onsetTimestamp,
                            new TimingOffsets(
                                onsetTimestamp === 0 ? -.2 : onsetTimestamp - startAllowance * this.offsetsConfig.correctRestOnsetPct,
                                offsetTimestamp - endAllowance * this.offsetsConfig.correctRestOffsetPct,
                                onsetTimestamp === 0 ? -.2 : onsetTimestamp - startAllowance * this.offsetsConfig.mistimedRestOnsetPct,
                                offsetTimestamp - endAllowance * this.offsetsConfig.mistimedRestOffsetPct
                            ),
                            note.graphics,
                            note.length,
                            !note.printObject,
                            note.staffPosition === 'bottom' ? HANDEDNESS.LEFT : HANDEDNESS.RIGHT
                        )
                        rests.push(restEvent)
                    } else {
                        const noteOnTimestamps = new TimingOffsets(
                            onsetTimestamp === 0 ? -.2 : onsetTimestamp - startAllowance * this.offsetsConfig.correctNoteOnsetStartPct,
                            onsetTimestamp + endAllowance * this.offsetsConfig.correctNoteOnsetEndPct,
                            onsetTimestamp === 0 ? -.2 : onsetTimestamp - startAllowance * this.offsetsConfig.mistimedNoteOnsetStartPct,
                            onsetTimestamp + endAllowance * this.offsetsConfig.mistimedNoteOnsetEndPct
                        )
    
                        const noteOffTimestamps = new TimingOffsets(
                            correctNoteOffsetStartTimestamp,
                            offsetTimestamp + endAllowance * this.offsetsConfig.correctNoteOffsetEndPct,
                            correctNoteOffsetStartTimestamp - noteDuration*0.2,
                            offsetTimestamp + endAllowance * this.offsetsConfig.mistimedNoteOffsetEndPct
                        )
    
                        const graphics:  PrerenderedGraphics[] = note.noteTie
                            ? note?.noteTie?.Notes.map((n: any) => n.graphics)
                            : note.graphics
                        if(note.staffPosition === 'unknown') {
                            console.warn("unknown staff position on note")
                        }
                        const noteEvent = new NoteEvent(
                            onsetTimestamp, 
                            note.pitch.halfTone+12,
                            noteOnTimestamps, 
                            noteOffTimestamps,
                            graphics,
                            note.length,
                            iterator.phrase,
                            note.staffPosition === 'bottom' ? HANDEDNESS.LEFT : HANDEDNESS.RIGHT
                        )
                        notes.push(noteEvent)
                        

                    }
                }
            });

            // Check if there's already a bucket for this timestamp.
            // OSMD's iterator WILL repeat timestamps.
            let bucket = this.eventBuckets.find(b => Math.abs(b.timestamp - onsetTimestamp) < Number.EPSILON)
            if (bucket === undefined) {
                bucket = new EventBucket(onsetTimestamp, notes, rests, iterator.phrase)
                iterator?.phrase?.pushPhrasePlayData(bucket)
                this.eventBuckets.push(bucket)
            } else {
                bucket.addEvents(notes, rests)
            }

            iterator.next()
        }

        // Tell all the event buckets to sort their internals and then we'll sort the buckets themselves.
        this.eventBuckets.forEach(b => b.sort())
        this.eventBuckets.sort((b1, b2) => b1.minOnsetTimestamp - b2.minOnsetTimestamp)
        if(iterator.phrase) {
          this.phraseHistory.push(iterator.phrase);
        }
    }

     // this function returns the last phrase N before the current phrase - starting with 1 being the phrase
    // directly before the current phrase
    getNBeforePhrase = (nBefore:number): Phrase | undefined => {
        let currentIndex = this.onsetIndex;
        let nPhrases = 0;
        let currentPhrase = this.eventBuckets[currentIndex]?.phraseRef
        if(currentPhrase) {
          while (nPhrases < nBefore && currentPhrase) {

            if(!(this.eventBuckets[currentIndex]?.phraseRef == currentPhrase)) {
                nPhrases++;
            }
            currentIndex --;
          }
          return this.eventBuckets[currentIndex]?.phraseRef
        }
        
    }


    handleNoteOnEvent(midiNote: number, velocity: number) {
        if(!this.errorRecognitionActive) {return}
        const timestamp = this.timeKeeper.audioTimeToMeasureTimestamp()
        if(this.timeKeeper.isPaused()) {
            return
        }
        // Look for the played MIDI note, or a closest match, in each possible note bucket that we're in right now.
        let closestNoteMatch: NoteEvent | null = null
        let closestPitchDiff: number = -1
        let restMatches: RestEvent[] = []
        let possibleNotes: NoteEvent[] = []
        let exactMatchFound = false
        let possibleNoteBuckets: EventBucket[] = []
        let offTime = false
        let earlyOnset = false
        let lateOnset = false
        let latestOnsetIndex = 0
        for (let i = (this.onsetIndex > 0 ? this.onsetIndex - 1: this.onsetIndex) ; i < this.eventBuckets.length; i++) 
        {
            let bucket = this.eventBuckets[i]
            // What this would do, if commented in would remove notes from the hand not active, hence we would mark these left hand notes incorrect.
            // Other version of the left/right hand accuracy would utilize this.
            // bucket.notes = bucket.notes.filter((note: any) => this.checkAccuracyNoteHand(note, errorRecognitionActiveRight, errorRecognitionActiveLeft))
            // if (bucket.notes.length == 0) { continue }

            if (!this.isInBucket(timestamp, i)) {
                continue
            }
            else if (timestamp < bucket.minOnsetTimestamp) {
                break
            }
            latestOnsetIndex = i;
            possibleNotes = possibleNotes.concat(bucket.notes)
            possibleNoteBuckets = possibleNoteBuckets.concat(bucket);
            restMatches = restMatches.concat(bucket.rests);
            
            for (const noteEvent of bucket.notes) {
                // Skip this note if we're outside its time range.
                if (timestamp < noteEvent.noteOnTimingOffsets.mistimedStartTimestamp
                    || timestamp > noteEvent.noteOnTimingOffsets.mistimedEndTimestamp) {
                    continue
                }
              

                const pitchDiff = Math.abs(noteEvent.midiNote - midiNote)
                // Exact match found! We can bail out of the outer loop now.
                // but, if it's already set, skip to the next closest
                if (pitchDiff === 0 ) {
                    closestNoteMatch = noteEvent
                    exactMatchFound = true
                    closestPitchDiff = 0
                    if(timestamp < noteEvent.noteOnTimingOffsets.correctStartTimestamp) {
                        earlyOnset = true
                    }
                    if(timestamp > noteEvent.noteOnTimingOffsets.correctEndTimestamp) {
                        lateOnset = true
                    }
                    // break
                }
                // Remember this note if it's the first note we've come across.
                else if (closestNoteMatch == null) {
                    closestNoteMatch = noteEvent
                    closestPitchDiff = pitchDiff
                }
                else {
                    // Remember this note if its pitch is equal or closer than the current closest match,
                    // and the note's timestamp is the closer to the current timestamp.
                    const closestMatchTimestampDiff = Math.abs(closestNoteMatch.timestamp - timestamp)
                    const timestampDiff = Math.abs(noteEvent.timestamp - timestamp)
                    if (closestPitchDiff >= pitchDiff && closestMatchTimestampDiff >= timestampDiff) {
                        closestNoteMatch = noteEvent
                        closestPitchDiff = pitchDiff
                    }
                }
            }

            // for (const rest of bucket.rests) {
            //     console.log("comparing timestamp: ", rest)
            //     if (timestamp < rest.timingOffsets.mistimedStartTimestamp
            //         || timestamp > rest.timingOffsets.mistimedEndTimestamp) {
            //         console.log("timestamp skipped")
            //         continue
            //     }
            //     restMatch = rest;
            //     console.log("Rest match: ", rest)
            // }

            // TODO look at rests!!
            // We should only match on a rest if there are no possible notes we could match against.
        }
        // we want the latest bucket to trump earliest 
        possibleNotes.sort((a, b) => b.timestamp - a.timestamp)
        
        // If an exact match was found, we know which note to color.
        possibleNoteBuckets.sort((eventBucket1, eventBucket2) => Math.abs(eventBucket1.timestamp - timestamp) - Math.abs(eventBucket2.timestamp - timestamp))
        // You shouldn't be able to change a note state after it's been set in offset state. However, we allow it in chords.
        // That means that it can happen if a note happens to be a chord. this fixes that 
        
        const isAttemptedNoteInChord = 
            (possibleNoteBuckets.length > 0 && (possibleNoteBuckets[0].notes.length > 1) )

        if(latestOnsetIndex >= this.onsetIndex || isAttemptedNoteInChord
            ) {
            if (closestNoteMatch && exactMatchFound && closestNoteMatch.getOffsetState() === MidiEventState.UNSET) {
                const closestNoteBucket = possibleNoteBuckets[0]
                if (!this.checkAccuracyNoteHand(closestNoteMatch)) return
    
                const isChord = closestNoteBucket.notes.length > 1;
                // Are we in the note's correct zone?
                // We already checked earlier if we're in the mistimed zone, so we only need to check the correct zone now.
                if (closestNoteMatch.noteOnTimingOffsets.mistimedStartTimestamp <= timestamp
                    && closestNoteMatch.noteOnTimingOffsets.mistimedEndTimestamp >= timestamp
                    // another check for whether the note's already been played through before pause set back
                    && closestNoteMatch.getOffsetState() === MidiEventState.UNSET) 
                {
                    if(closestNoteMatch.getOnsetState() === MidiEventState.UNSET ) {
                        if(!(earlyOnset || lateOnset)) {
                            this.markOnsetAccuracyWithSideEffects(closestNoteMatch, MidiEventState.HIT, true)
                        } else if(earlyOnset){
                            this.markOnsetAccuracyWithSideEffects(closestNoteMatch, MidiEventState.EARLY_ONSET)
                        } else if(lateOnset) {
                            this.markOnsetAccuracyWithSideEffects(closestNoteMatch, MidiEventState.LATE_ONSET)
                        }
                       
                        // if we're in a chord it's okay to change a missed to an off time
                        // however, when rewound after pause, it's impossible to tell the difference
                        // between a note that was missed due to a previous miss or a miss that just ocurred
                        // while attempting to play a chord. So, use the index discovered in the iteration above
                        // in comparison to the ever advancing onset index to check if we're in a re-wound state.
                        // bit janky but might work.
                    } else if ( 
                        !isErrorState(closestNoteMatch.getOnsetState()) ||
                               (isErrorState(closestNoteMatch.getOnsetState()) && isChord)) {

                        // should never go from missed to offtime
                        this.markOnsetAccuracyWithSideEffects(closestNoteMatch, MidiEventState.ADDITIONAL_NOTE, false, midiNote)
                    }  else if(!isErrorState(closestNoteMatch.getOnsetState())){
                        this.markOnsetAccuracyWithSideEffects(closestNoteMatch, MidiEventState.ADDITIONAL_NOTE, false, midiNote)
                    }
                    this.activeOnsetEvents[closestNoteMatch.midiNote] = closestNoteMatch
                }
            } else if (
                (closestNoteMatch != null && possibleNotes.length == 1 && !(possibleNoteBuckets[0].rests.length > 0 && possibleNoteBuckets[0].notes.length === 0)) || 
                (closestNoteMatch == null && possibleNotes.length == 1 && !(possibleNoteBuckets[0].rests.length > 0 && possibleNoteBuckets[0].notes.length === 0))
                ) {
                if(closestNoteMatch === null) {
                    // not sure why there are situations where closest match is null and possible notes is 1, but not going to fix it right now
                    // Also check for rest in case closest note bucket is a rest
                    closestNoteMatch = possibleNotes[0]
                }
                if (!this.checkAccuracyNoteHand(closestNoteMatch)) return
                // never set a note with an offset state
                if(closestNoteMatch.getOffsetState() === MidiEventState.UNSET) {
                    // There's only one note in this time range we can match against. Color the note red.
                    if (closestNoteMatch.noteOnTimingOffsets.mistimedStartTimestamp <= timestamp
                        && closestNoteMatch.noteOnTimingOffsets.mistimedEndTimestamp >= timestamp) 
                    {
                        if(closestNoteMatch.getOnsetState() === MidiEventState.UNSET) {
                            this.markOnsetAccuracyWithSideEffects(closestNoteMatch, MidiEventState.WRONG_NOTE, false, midiNote)
                        } else if(closestNoteMatch.getOnsetState() === MidiEventState.HIT) {
                            this.markOnsetAccuracyWithSideEffects(closestNoteMatch, MidiEventState.ADDITIONAL_NOTE, false, midiNote)
                        }
                        this.activeOnsetEvents[midiNote] = closestNoteMatch
                    }
                }
            } else if (possibleNotes.length > 1) {
                // at this point we know this note is extraneous (probably, unless it's an exact match that slipped through, but we look for that below) 
                // but we need to figure out which note to color in.
                // sort buckets by latest because we don't want to
                // sort by closeness to midi note
                possibleNotes.sort((note1, note2) => Math.abs(midiNote - note1.midiNote) - Math.abs(midiNote - note2.midiNote))
                let closestInBucket: NoteEvent | undefined = undefined;
    
                const allPlayed =  every(possibleNotes, note => note.getOnsetState() !== MidiEventState.UNSET)
                if(allPlayed) {
                    // if everything's been hit we can just adjust the most recent note hit. 
                    // with the exception that we don't change missed to offtime, so find the first
                    // non missed
                    for(let i = 0; i < possibleNotes.length; i++) {
                        const currNote = possibleNotes[i];
                        const inTimespan = currNote.noteOnTimingOffsets.mistimedStartTimestamp <= timestamp 
                            && currNote.noteOffTimingOffsets.correctStartTimestamp >= timestamp 
                        // There's a possibility that exact note isn't caught in the initial note matching logic above if it
                        // comes in after mistimed start time allowance. This exact note circuitbreaker catches that.
                        // In the case that the exact match was already set as missed - we don't want to continue looking
                        // for the next note to mark offtime. Instead, do nothing (if already missed) or mark offtime
                        exactMatchFound = currNote.midiNote === midiNote
                        if (inTimespan &&
                            (exactMatchFound ||
                            (!isErrorState(currNote.getOnsetState()) && currNote.getOffsetState() === MidiEventState.UNSET))) {
                            closestInBucket = currNote
                            break;
                        }
                    }
                    if(closestInBucket) {
                        // if(isChord && closestInBucket.getOnsetState() === MidiEventState.MISSED) {
                        //     console.log("marking note off time because mistimed end in note off event")
                        //     this.markAccuracy(closestInBucket.getOnsetState(), MidiEventState.OFF_TIME)
                        //     this.makeNoteMistimed(closestInBucket.graphics)
                        //     closestInBucket.getOnsetState() = MidiEventState.OFF_TIME
                        // }
                        if(!isErrorState(closestInBucket.getOnsetState()) && closestInBucket.getOffsetState() === MidiEventState.UNSET) {
                            this.markOnsetAccuracyWithSideEffects(closestInBucket, MidiEventState.ADDITIONAL_NOTE, false, midiNote)
                        }
                        this.activeOnsetEvents[midiNote] = closestInBucket
                        closestNoteMatch = closestInBucket
                    }
                } else {
                    // if not every note's been played, instead look for the first non set note, or an exact match that fell
                    // through above and is therefore an offtime.
                    for(let i = 0; i < possibleNotes.length; i++) {
                        const currNote = possibleNotes[i];
                        const inTimespan = currNote.noteOnTimingOffsets.mistimedStartTimestamp <= timestamp 
                            && currNote.noteOffTimingOffsets.correctStartTimestamp >= timestamp     
                            exactMatchFound = currNote.midiNote === midiNote
                        if (inTimespan && 
                            (exactMatchFound ||
                            (currNote.getOnsetState() ===  MidiEventState.UNSET && currNote.getOffsetState() === MidiEventState.UNSET))) {
                            closestInBucket = currNote
                            closestNoteMatch = closestInBucket
                            break;
                        } 
                    }
                    if (closestInBucket && !this.checkAccuracyNoteHand(closestInBucket)) return

                    if(closestInBucket && exactMatchFound && closestInBucket.getOffsetState() === MidiEventState.UNSET) {
                        // this catches an exact match that fell through the initial note matching logic. The only 
                        // way to fall through the initial note matching logic above is by being outside the correct 
                        // start onset bucket - so we can just mark it offtime. It's okay if it's a miss, because
                        // we can change miss to off time within a chord
                        if(earlyOnset) {
                            this.markOnsetAccuracyWithSideEffects(closestInBucket, MidiEventState.EARLY_ONSET)
                        } else if(lateOnset) {
                            this.markOnsetAccuracyWithSideEffects(closestInBucket, MidiEventState.LATE_ONSET)
                        } else {
                            console.error("exact match fell through but was not early or late")
                            this.markOnsetAccuracyWithSideEffects(closestInBucket, MidiEventState.HIT, true)
                        }

                    } else if(closestInBucket && !isErrorState(closestInBucket.getOnsetState()) && closestInBucket.getOffsetState() === MidiEventState.UNSET) {
                        // otherwise mark the first non set a miss
                        this.markOnsetAccuracyWithSideEffects(closestInBucket, MidiEventState.WRONG_NOTE, false, midiNote)

                    }
                }
            } else {
                // Do rest logic here, by now we've confirmed there are absolutely no notes in this time range.
                // Even though this is the same as the above conditional, we should  keep them separate in case
                // we wnt do something differently.
                restMatches.sort((rest1, rest2) => Math.abs(midiNote - rest1.timestamp) - Math.abs(midiNote - rest2.timestamp))
    
                let closestRestMatch: RestEvent | undefined = restMatches.length > 0 ? restMatches[0] : undefined
                for(let rest of restMatches ) {
                    if(rest.state === MidiEventState.UNSET &&
                        !rest.hidden &&
                        (!closestRestMatch || 
                            (closestRestMatch && 
                                (Math.abs(timestamp - rest.timestamp) < Math.abs(timestamp - closestRestMatch.timestamp))))
                    ) {
                        closestRestMatch = rest
                    }
                }
                if(closestRestMatch && !closestRestMatch.hidden) {
                    this.markRestAccuracyWithSideEffects(closestRestMatch, midiNote)
                    if(closestRestMatch?.hand === HANDEDNESS.LEFT) {
                      closestRestMatch?.setLhSnapshotPhraseAccuracy(this.calcLHAccuracy())
                    }
                    if(closestRestMatch?.hand === HANDEDNESS.RIGHT) {
                      closestRestMatch?.setRhSnapshotPhraseAccuracy(this.calcRHAccuracy())
                    }
                    closestRestMatch?.setSnapshotPhraseAccuracy(this.calcAccuracy())
                }
            }
        }
        const currentPhrase = this.getNBeforePhrase(0);
        // this should run after the above so that accuracy includes the latest note
        if(currentPhrase) {
            // we know that any of the potential phrase event buckets are probably of the current phrase
            currentPhrase.pushEvent({midiEvent: MidiEventType.NOTE_ON, midiNote, timestamp: timestamp - currentPhrase.getStartTimestamp()})
            currentPhrase.pushRHRunningAccuracy(this.calcAccuracy())
            // we don't want to add "100%" if the phrase doesn't have left handed notes
            if(closestNoteMatch) {
              closestNoteMatch?.setSnapshotPhraseAccuracy(this.calcAccuracy())
              if(closestNoteMatch?.hand === HANDEDNESS.LEFT) {

                closestNoteMatch?.setLhSnapshotPhraseAccuracy(this.calcLHAccuracy())
              }
              if(closestNoteMatch?.hand === HANDEDNESS.RIGHT) {
                closestNoteMatch?.setRhSnapshotPhraseAccuracy(this.calcRHAccuracy())
              }
            }
            currentPhrase.getHasLeftHandedNotes() && currentPhrase.pushLHRunningAccuracy(this.calcLHAccuracy())
            currentPhrase.getHasRightHandedNotes() && currentPhrase.pushRHRunningAccuracy(this.calcRHAccuracy())
        }
    }
    

    handleNoteOffEvent(midiNote: number) {
        const timestamp = this.timeKeeper.audioTimeToMeasureTimestamp()
        if(this.timeKeeper.isPaused()) {
            return
        }
        const currentPhrase = this.getNBeforePhrase(0);
        if(currentPhrase) {
            currentPhrase.pushEvent({midiEvent: MidiEventType.NOTE_OFF, midiNote, timestamp: timestamp - currentPhrase.getStartTimestamp()})
        }
        if(!this.errorRecognitionActive.left && !this.errorRecognitionActive.right) {
          logger.debug("error rec turned off - returning from note off")
          return
        }
        // Offset events are much easier to handle than onset events!
        // We already matched the onset event earlier and kept track of it. 
        // All we have to do now is re-color the note if an offset event was out of range.
        const match = this.activeOnsetEvents[midiNote]
        if(match?.hand === HANDEDNESS.LEFT && !this.errorRecognitionActive.left) {
          logger.debug("left hand error rec turned off - returning from note off")

          return
        }
        if(match?.hand === HANDEDNESS.RIGHT && !this.errorRecognitionActive.right) {
          logger.debug("right hand error rec turned off - returning from note off")

          return
        }
        // logger.debug("note off match found?", match)
        if (match != null && match.getOffsetState() === MidiEventState.UNSET) {
            // logger.debug("offset state is unset")
            const timingOffsets = match.noteOffTimingOffsets
            // logger.debug(timingOffsets)
            // logger.debug(timestamp)
            // logger.debug("timestamp >= timingOffsets.correctStartTimestamp && timestamp <= timingOffsets.correctEndTimestamp", timestamp >= timingOffsets.correctStartTimestamp && timestamp <= timingOffsets.correctEndTimestamp)
            // console.log("offset match for midiNote " + midiNote + " at timestamp " + timestamp + ", timing offsets are ("
            //     + timingOffsets.correctStartTimestamp  + ", " + timingOffsets.correctEndTimestamp + "), (" 
            //     + timingOffsets.mistimedStartTimestamp + ", " + timingOffsets.mistimedEndTimestamp);
            if(match.getOnsetState() === MidiEventState.WRONG_NOTE) {
                this.setOffsetAccuracy(match, MidiEventState.WRONG_NOTE) // if onset is wrong note, offset is as well
            } else if (timestamp >= timingOffsets.correctStartTimestamp && timestamp <= timingOffsets.correctEndTimestamp) {
                // WOOHOO, they hit the correct zone
                // make sure not to over-write an offtime start
                // console.log("offset state is out of time")
                // console.log("!isSemiErrorState(match.getOnsetState())", !isSemiErrorState(match.getOnsetState()))
                if(!isSemiErrorState(match.getOnsetState())) {
                    this.markOffsetAccuracyWithSideEffects(match, MidiEventState.HIT)
                }
                
                // else if (timestamp <= timingOffsets.mistimedStartTimestamp) {
                //     this.markOffsetAccuracyWithSideEffects(match, MidiEventState.EARLY_OFFSET)
                // } else if( timestamp >= timingOffsets.correctEndTimestamp) {
                //     this.markOffsetAccuracyWithSideEffects(match, MidiEventState.LATE_OFFSET)
                // } 
            } else if (timestamp <= timingOffsets.mistimedStartTimestamp) {
              this.markOffsetAccuracyWithSideEffects(match, MidiEventState.EARLY_OFFSET)
            } else if( timestamp >= timingOffsets.correctEndTimestamp) {
              this.markOffsetAccuracyWithSideEffects(match, MidiEventState.LATE_OFFSET)
            } 

            // Now that we've processed the note off event, we're no longer actively tracking this note,
            // and can unset it in the events array.
            this.activeOnsetEvents[midiNote] = null
        }
    } 

    checkAccuracyNoteHand(note: NoteEvent | null) {
      if (note != null) {
        if (note.hand === HANDEDNESS.RIGHT && !this.errorRecognitionActive.right) return false
        if (note.hand === HANDEDNESS.LEFT && !this.errorRecognitionActive.left) return false
      }
      return true
    }

    // Should be called on a set interval. Looks for missed notes and colors them yellow/red.
    update() {
        if(!this.errorRecognitionActive.left && !this.errorRecognitionActive.right) {return}
        // Don't update if we re-wound and are counting back in.
        const timestamp = this.timeKeeper.audioTimeToMeasureTimestamp()
        if (timestamp < this.lastProcessedTimestamp || this.missedNotesIndex >= this.eventBuckets.length) {
            return
        }
        this.lastProcessedTimestamp = timestamp
        
        // This was to stop all error recognition.
        // if (!errorRecognitionActiveLeft || !errorRecognitionActiveRight) {
        //   return
        // }

        // Look for notes in buckets that should be colored yellow or red.
        for (let i = this.onsetIndex; i < this.eventBuckets.length && this.isInBucket(timestamp, i); i++) {
            const bucket = this.eventBuckets[i]
            bucket.notes.forEach(note => {
                if (!this.checkAccuracyNoteHand(note)) return

                // If a note on event has been received for this note, ignore it.
                if (note.getOnsetState() != MidiEventState.UNSET) {
                    return
                }

                if (timestamp > note.noteOnTimingOffsets.mistimedEndTimestamp) {
                    // Mistimed window was missed, color error
                    this.markOnsetAccuracyWithSideEffects(note, MidiEventState.NEVER_PLAYED)

                    note.setOffsetState(MidiEventState.NEVER_PLAYED)
                } else if (timestamp > note.noteOnTimingOffsets.correctEndTimestamp) {
                    // if(note.getOnsetState() === MidiEventState.UNSET) {
                    //     this.markAccuracy(note.getOnsetState(), MidiEventState.MISSED)
                    // }
                    // note.getOnsetState() = MidiEventState.MISSED
                    // Correct window was missed, color mistimed
                    // REMOVED by request of Patrick and Gizzi
                    // this.makeNoteYellow(note.graphic)
                    // note.state = MidiEventState.MISSED
                    // Hmmm but should we factor accuracy?
                }
            })
        }

        // Look for held-down notes whose offset buckets were missed.
        this.activeOnsetEvents.forEach(note => {
            if (!this.checkAccuracyNoteHand(note)) return
          
            if (note && note.getOffsetState() == MidiEventState.UNSET 
                && note.noteOffTimingOffsets.mistimedEndTimestamp < timestamp)
            {
                if(note.getOnsetState() === MidiEventState.UNSET) {
                    this.markOffsetAccuracyWithSideEffects(note, MidiEventState.NEVER_PLAYED)
                } else if(note.getOnsetState() === MidiEventState.HIT) {
                    this.markOffsetAccuracyWithSideEffects(note, MidiEventState.LATE_OFFSET)
                } 
            }
        })
        // Advance the onset index if we've exceeded the max onset for this bucket.
        while (this.eventBuckets[this.onsetIndex] && timestamp > this.eventBuckets[this.onsetIndex].maxOnsetTimestamp) {
            this.eventBuckets[this.onsetIndex].notes.forEach(note => {
                if (!this.checkAccuracyNoteHand(note)) return

                if (note.getOnsetState() === MidiEventState.UNSET) {
                    this.markOnsetAccuracyWithSideEffects(note, MidiEventState.NEVER_PLAYED)
                    this.setOffsetAccuracy(note, MidiEventState.NEVER_PLAYED)
                }
            })
            // this.eventBuckets[this.onsetIndex].errorMissedNotes()
            this.onsetIndex++
        }
    }

    private calculateTiedNoteLength(note: Note): number {
        if (note.NoteTie.Notes[0] == note) {
            return note.NoteTie.Duration.RealValue
        }
        return -1
    }

    // This differs from the above function in that it expects note data prerendered from the parsing lambda
    // private calculateTiedNoteLengthPrerendered(note: Tie): number {
    //     if (note.NoteTie.Notes[0] == note) {
    //         return note.NoteTie.Duration.RealValue
    //     }
    //     return -1
    // }
    
    private calculateCorrectNoteOffMin(noteDuration: number) {
        if (noteDuration <= 1.0/4.0) {
            return noteDuration * 0.5
        }
        // If duration is between a quarter note and half note, use this block.
        else if (noteDuration > 1.0/4.0 && noteDuration < 2.0/4.0) {
            const nRange = (2.0/4.0) - (1.0/4.0)
            // Scale from 0.5 to 0.625 of the note, on a nearly linear curve
            let nScale = 1.0 - ((2.0/4.0) - noteDuration) / nRange
            // nScale is normalized from 0..1; putting that on a curve nScale^0.6
            // lets a noteDuration=1.5 have minimum offset of just under 1 beat.
            // Adjust the following line to adjust how "aggressive" scaling between 1 and 2 is.
            // Use graphtoy.com to visualize the curve you're adjusting.
            nScale = Math.pow(nScale, 0.6)
            return (1.0/8.0) + (0.1875) * nScale
        } else {
            return noteDuration - (1.0/4.0) + (1.0/16.0)
        }
    }

    protected pulseMatchedNote(note: NoteEvent, colorClass: string) {
        const vgNote = (note.graphics[0] as unknown as {staveNote: { id: string }})
        // const staveNote = vgNote.getSVGGElement()
        let staveNote = document.getElementById(vgNote.staveNote.id)
        // Notes with staves will report the wrong height; we have to grab a descendant
        // which is ONLY the note head so we calculate pulse height offset correctly.
        const noteHead = staveNote?.querySelector('.vf-notehead')

        if (noteHead == null) {
            console.error("note has no note head!!")
            return
        }
        let colorClassCSSPostfix = 'correct-green' 
        if(colorClass === ERROR_REC_CLASS.CORRECT && this.colorblindType === COLORBLIND_TYPE.RED_GREEN) {
          colorClassCSSPostfix = 'correct-blue' 
        } else if(colorClass === ERROR_REC_CLASS.CORRECT && this.colorblindType === COLORBLIND_TYPE.FULL) {
          colorClassCSSPostfix = 'correct-white'
        } 
        
        
        // Get the bounding rect of the note head relative to the document body.
        // We will append a pulse to the document, and position it over the note.
        const boundingRect = noteHead.getBoundingClientRect()

        // Create the pulse.
        const pulse = document.createElement("div")
        pulse.classList.add("pulsating-circle", colorClassCSSPostfix)
        document.body.appendChild(pulse)

        // Position the pulse.
        pulse.style.left = (boundingRect.x + boundingRect.width / 2) + "px"
        pulse.style.top = (window.scrollY + boundingRect.y + boundingRect.height / 2) + "px"

        // !!TODO AUSTIN!! You still need to track and delete the pulses!!!!!
    }

    private isOnsetTimeInBucket(timestamp: number, bucketIndex: number) {
        const minOnsetTimestamp = this.eventBuckets[bucketIndex].minOnsetTimestamp;
        const maxOnsetTimestamp = this.eventBuckets[bucketIndex].maxOnsetTimestamp;
        // console.log("minOnsetTimestamp = " + minOnsetTimestamp)
        // console.log("maxOnsetTimestamp = " + maxOnsetTimestamp )
        // console.log("timestamp = " + timestamp)
        // console.log( timestamp > minOnsetTimestamp
        //     && timestamp < maxOnsetTimestamp)
        return timestamp > minOnsetTimestamp
            && timestamp < maxOnsetTimestamp
    }

    private isInBucket(timestamp: number, bucketIndex: number) {
        const minOnsetTimestamp = this.eventBuckets[bucketIndex].minOnsetTimestamp;
        const maxOffsetTimestamp = this.eventBuckets[bucketIndex].maxOffsetTimestamp;
        // console.log("minOnsetTimestamp = " + minOnsetTimestamp)
        // console.log("maxOnsetTimestamp = " + maxOnsetTimestamp )
        // console.log("timestamp = " + timestamp)
        // console.log( timestamp > minOnsetTimestamp
        //     && timestamp < maxOnsetTimestamp)
        return timestamp > minOnsetTimestamp
            && timestamp < maxOffsetTimestamp
    }

    public clearErrors() {
        for (const eventBucket of this.eventBuckets ) {
            eventBucket.notes.forEach(note => {
                resetNote(note.graphics)
                note.setOffsetState(MidiEventState.UNSET)
                note.setOnsetState(MidiEventState.UNSET)
            })
        }
        this.accuracyQueue = []
    }

  protected makeNoteMistimed = (graphics: PrerenderedGraphics[]) =>
    makeNoteSemiError(graphics, this.colorblindType);
  //protected this.makeNoteRed(graphics: PrerenderedGraphics[]) {}
  protected makeNoteCorrect = (graphics: PrerenderedGraphics[]) =>
    makeNoteCorrect(graphics, this.colorblindType);
  protected makeNoteWrong = (graphics: PrerenderedGraphics[]) =>
    makeNoteError(graphics, this.colorblindType);
}