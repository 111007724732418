import { ERROR_RECOGNITION_MODE, MidiEventState, MidiEventType } from "Types";


export interface EventState {
  sessionId: string | undefined,
  queue: EventTypes[]
}

export interface EventReducer {
  eventReducer: EventState,
}

export enum EventType {
  AccountVerifiedEvent = 'AccountVerifiedEvent',
  TempoAdjustmentEvent = 'TempoAdjustmentEvent',
  VisitEvent = 'VisitEvent',
  SignInEvent = 'SignInEvent',
  PageViewEvent = 'PageViewEvent',
  MidiConnectionSuccessEvent = 'MidiConnectionSuccessEvent',
  MidiConnectionFailureEvent = 'MidiConnectionFailureEvent',
  WelcomeLetterEvent = 'WelcomeLetterEvent',
  LevelCompletedEvent = 'LevelCompletedEvent',
  LevelStartedEvent = 'LevelStartedEvent',
  LevelPlayingEvent = 'LevelPlayingEvent',
  LevelPlayEvent = 'LevelPlayEvent',
  LevelPauseEvent = 'LevelPauseEvent',
  RepertoireAttemptEvent = 'RepertoireAttemptEvent',
  RepertoireSessionFinishedEvent = 'RepertoireSessionFinishedEvent',
  RepertoireStartedEvent = 'RepertoireStartedEvent',
  RepertoirePlayingEvent = 'RepertoirePlayingEvent',
  RepertoireCompletedEvent = 'RepertoireCompletedEvent',
  TierSessionFinishedEvent = 'TierSessionFinishedEvent',
  TierCompletedEvent = 'TierCompletedEvent',
  TierStartedEvent = 'TierStartedEvent',
  TrialStartedEvent = 'TrialStartedEvent',
  TrialLapsedEvent = 'TrialLapsedEvent',
  TutorialStartedEvent = 'TutorialStartedEvent',
  TutorialChapterStartedEvent = 'TutorialChapterStartedEvent',
  TutorialChapterCompletedEvent = 'TutorialChapterCompletedEvent',
  TutorialCompletedEvent = 'TutorialCompletedEvent',
  TutorialSkippedEvent = 'TutorialSkippedEvent',
  MobileBlockedEvent = 'MobileBlockedEvent',
  PhraseCompletedEvent = 'PhraseCompletedEvent',
  PhraseErrorEvent = 'PhraseErrorEvent',
  SafariBlockedEvent = 'SafariBlockedEvent',
  SubscriptionStartedEvent = 'SubscriptionStartedEvent',
  SubscriptionCanceledEvent = 'SubscriptionCanceledEvent',
}


export enum EventActionTypes {
  SET_SESSION_ID = "SET_SESSION_ID",
  SET_EVENT_QUEUE = "SET_EVENT_QUEUE",
  DISPATCH_EVENTS = "DISPATCH_EVENTS",
  DISPATCH_EVENTS_SUCCESS = "DISPATCH_EVENTS_SUCCESS",
  FLUSH_QUEUE = "FLUSH_QUEUE",
  ACCOUNT_VERIFIED_EVENT_ACTION = 'ACCOUNT_VERIFIED_EVENT_ACTION',
  TEMPO_ADJUSTMENT_EVENT_ACTION = 'TEMPO_ADJUSTMENT_EVENT_ACTION',
  VISIT_EVENT_ACTION = 'VISIT_EVENT_ACTION',
  SIGN_IN_EVENT_ACTION = "SIGN_IN_EVENT_ACTION",
  PAGE_VIEW_EVENT_ACTION = 'PAGE_VIEW_EVENT_ACTION',
  MIDI_CONNECTION_FAILURE_EVENT_ACTION = 'MIDI_CONNECTION_FAILURE_EVENT_ACTION',
  MIDI_CONNECTION_SUCCESS_EVENT_ACTION = "MIDI_CONNECTION_SUCCESS_EVENT_ACTION",
  WELCOME_LETTER_EVENT_ACTION = 'WELCOME_LETTER_EVENT_ACTION',
  LEVEL_STARTED_EVENT_ACTION = 'LEVEL_STARTED_EVENT_ACTION',
  LEVEL_COMPLETED_EVENT_ACTION = 'LEVEL_COMPLETED_EVENT_ACTION',
  LEVEL_PLAYING_EVENT_ACTION = 'LEVEL_PLAYING_EVENT_ACTION',
  LEVEL_PLAY_EVENT_ACTION = 'LEVEL_PLAY_EVENT_ACTION',
  LEVEL_PAUSE_EVENT_ACTION = 'LEVEL_PAUSE_EVENT_ACTION',
  REPERTOIRE_ATTEMPT_EVENT_ACTION = 'REPERTOIRE_ATTEMPT_EVENT_ACTION',
  REPERTOIRE_SESSION_FINISHED_EVENT_ACTION = 'REPERTOIRE_SESSION_FINISHED_EVENT_ACTION',
  REPERTOIRE_STARTED_EVENT_ACTION = "REPERTOIRE_STARTED_EVENT_ACTION",
  REPERTOIRE_PLAYING_EVENT_ACTION = "REPERTOIRE_PLAYING_EVENT_ACTION",
  REPERTOIRE_COMPLETED_EVENT_ACTION = "REPERTOIRE_COMPLETED_EVENT_ACTION",
  TIER_SESSION_FINISHED_EVENT_ACTION = 'TIER_SESSION_FINISHED_EVENT_ACTION',
  TIER_COMPLETED_EVENT_ACTION = 'TIER_COMPLETED_EVENT_ACTION',
  TIER_STARTED_EVENT_ACTION = 'TIER_STARTED_EVENT_ACTION',
  TRIAL_STARTED_EVENT_ACTION = 'TRIAL_STARTED_EVENT_ACTION',
  TRIAL_LAPSED_EVENT_ACTION = 'TRIAL_LAPSED_EVENT_ACTION',
  TUTORIAL_STARTED_EVENT_ACTION = 'TUTORIAL_STARTED_EVENT_ACTION',
  TUTORIAL_CHAPTER_STARTED_EVENT_ACTION = 'TUTORIAL_CHAPTER_STARTED_EVENT_ACTION',
  TUTORIAL_CHAPTER_COMPLETED_EVENT_ACTION = 'TUTORIAL_CHAPTER_COMPLETED_EVENT_ACTION',
  TUTORIAL_COMPLETED_EVENT_ACTION = 'TUTORIAL_COMPLETED_EVENT_ACTION',
  TUTORIAL_SKIPPED_EVENT_ACTION = 'TUTORIAL_SKIPPED_EVENT_ACTION',
  MOBILE_BLOCKED_EVENT_ACTION = 'MOBILE_BLOCKED_EVENT_ACTION',
  PHRASE_COMPLETED_EVENT_ACTION = 'PHRASE_COMPLETED_EVENT_ACTION',
  SAFARI_BLOCKED_EVENT_ACTION = 'SAFARI_BLOCKED_EVENT_ACTION',
  SUBSCRIPTION_STARTED_EVENT = 'SUBSCRIPTION_STARTED_EVENT',
  SUBSCRIPTION_CANCELED_EVENT = 'SUBSCRIPTION_CANCELED_EVENT'
}


export type PhraseNoteError = {
  note_error_type: MidiEventState,
  note: number | null,
  is_rest: boolean,
  tick: number,
  additional_note: number | null
}

export type MidiData = {midiEvent: MidiEventType, midiNote: number, timestamp: number}

export type BaseEvent = {
  datetime: string;
  analytics_session_id: string;
}

export type AccountVerifiedEvent = {
  event_type: EventType.AccountVerifiedEvent
}

export type TempoAdjustmentEvent = {
  event_type: EventType.TempoAdjustmentEvent,
  tempo: number
}

export type VisitEvent = {
  event_type: EventType.VisitEvent,
}

export type PageViewEvent = {
  event_type: EventType.PageViewEvent,
  page: string
}

export type WelcomeLetterEvent = {
  event_type: EventType.WelcomeLetterEvent,
}

export type SignInEvent = {
  event_type: EventType.SignInEvent
}

export type MidiConnectionSuccessEvent = {
  event_type: EventType.MidiConnectionSuccessEvent,
}

export type MidiConnectionFailureEvent = {
  event_type: EventType.MidiConnectionFailureEvent,
}



export type LevelPlayingEvent = {
  event_type: EventType.LevelPlayingEvent,
  play_time: number,
  level_number: number,
  play_session_id: string
}

export type LevelPlayEvent = {
  event_type: EventType.LevelPlayEvent,
  level_number: number,
  play_session_id: string
}

export type LevelPauseEvent = {
  event_type: EventType.LevelPauseEvent,
  level_number: number,
  play_session_id: string
}


export type LevelStartedEvent = {
  event_type: EventType.LevelStartedEvent,
  level_number: number,
  play_session_id: string
}


export type LevelCompletedEvent = {
  event_type: EventType.LevelCompletedEvent,
  level_number: number,
  play_session_id: string
}

export type RepertoirePlayingEvent = {
  event_type: EventType.RepertoirePlayingEvent,
  repertoire_id: number,
  play_session_id: string,
  play_time: number
}

export type RepertoireAttemptEvent = {
  event_type: EventType.RepertoireAttemptEvent,
  accuracy: number,
  completion: number,
  repertoire_id: number,
  play_session_id: string
}

export type RepertoireSessionFinishedEvent = {
  event_type: EventType.RepertoireSessionFinishedEvent,
  repertoire_id: number,
  play_session_id: string
}

export type RepertoireStartedEvent = {
  event_type: EventType.RepertoireStartedEvent,
  repertoire_id: number,
  play_session_id: string
}

export type RepertoireCompletedEvent = {
  event_type: EventType.RepertoireCompletedEvent,
  repertoire_id: number,
  play_session_id: string
}

export type TierStartedEvent = {
  event_type: EventType.TierStartedEvent,
  level_number: number,
  tier_number: number,
  play_session_id: string
}

export type TierSessionFinishedEvent = {
  event_type: EventType.TierSessionFinishedEvent,
  level_number: number,
  tier_number: number,
  play_session_id: string
}


export type TierCompletedEvent = {
  event_type: EventType.TierCompletedEvent,
  level_number: number,
  tier_number: number,
  play_session_id: string
}

export type TutorialStartedEvent = {
  event_type: EventType.TutorialStartedEvent,
  level_number: number,
}

export type TrialLapsedEvent = {
  event_type: EventType.TrialLapsedEvent,
}

export type TrialStartedEvent = {
  event_type: EventType.TrialStartedEvent,
}

export type TutorialChapterStartedEvent = {
  event_type: EventType.TutorialChapterStartedEvent,
  level_number: number,
  chapter: number
}

export type TutorialChapterCompletedEvent = {
  event_type: EventType.TutorialChapterCompletedEvent,
  level_number: number,
  chapter: number
}

export type TutorialCompletedEvent = {
  event_type: EventType.TutorialCompletedEvent,
  level_number: number,
}

export type TutorialSkippedEvent =  {
  event_type: EventType.TutorialSkippedEvent
  level_number: number,
  chapter: number
}

export type MobileBlockedEvent =  {
  event_type: EventType.MobileBlockedEvent
}

export type PhraseCompletedEvent = {
  event_type: EventType.PhraseCompletedEvent,
  midi_data: MidiData[],
  error_data: PhraseNoteError[],
  accuracy: number | null,
  avg_lh_accuracy: number | null,
  avg_rh_accuracy: number | null,
  avg_running_accuracy: number | null,
  avg_lh_running_accuracy: number | null,
  avg_rh_running_accuracy: number | null,
  total_notes: number,
  error_score: number,
  phrase_s3_id: string,
  level_number: number,
  tier_number: number,
  error_recognition_mode: ERROR_RECOGNITION_MODE,
  bpm: number,
  play_session_id: string

}

export type SafariBlockedEvent = {
  event_type: EventType.SafariBlockedEvent;
}

export type SubscriptionCanceledEvent = {
  event_type: EventType.SubscriptionCanceledEvent
}

export type SubscriptionStartedEvent = {
  event_type: EventType.SubscriptionStartedEvent
}

export interface SetSessionId {
  type: EventActionTypes.SET_SESSION_ID;
}

export interface VisitEventAction {
  type: EventActionTypes.VISIT_EVENT_ACTION;
  payload: VisitEvent
}

export interface PageViewEventAction {
  type: EventActionTypes.PAGE_VIEW_EVENT_ACTION;
  payload: PageViewEvent
}

export interface AccountVerifiedEventAction {
  type: EventActionTypes.ACCOUNT_VERIFIED_EVENT_ACTION;
  payload: AccountVerifiedEvent
}

export interface TempoAdjustmentEventAction {
  type: EventActionTypes.TEMPO_ADJUSTMENT_EVENT_ACTION;
  payload: TempoAdjustmentEvent
}

export interface WelcomeLetterEventAction {
  type: EventActionTypes.WELCOME_LETTER_EVENT_ACTION;
  payload: WelcomeLetterEvent
}

export interface SignInEventAction {
  type: EventActionTypes.SIGN_IN_EVENT_ACTION;
  payload: SignInEvent
}

export interface MidiConnectionSuccessEventAction {
  type: EventActionTypes.MIDI_CONNECTION_SUCCESS_EVENT_ACTION;
  payload: MidiConnectionSuccessEvent
}

export interface MidiConnectionFailureEventAction {
  type: EventActionTypes.MIDI_CONNECTION_FAILURE_EVENT_ACTION;
  payload: MidiConnectionFailureEvent
}

export interface LevelPlayingEventAction {
  type: EventActionTypes.LEVEL_PLAYING_EVENT_ACTION;
  payload: LevelPlayingEvent;
}

export interface LevelPlayEventAction {
  type: EventActionTypes.LEVEL_PLAY_EVENT_ACTION;
  payload: LevelPlayEvent;
}

export interface LevelPauseEventAction {
  type: EventActionTypes.LEVEL_PAUSE_EVENT_ACTION;
  payload: LevelPauseEvent;
}

export interface LevelStartedEventAction {
  type: EventActionTypes.LEVEL_STARTED_EVENT_ACTION;
  payload: LevelStartedEvent;
}

export interface LevelCompletedEventAction {
  type: EventActionTypes.LEVEL_COMPLETED_EVENT_ACTION;
  payload: LevelCompletedEvent;
}

export interface TierSessionFinishedEventAction {
  type: EventActionTypes.TIER_SESSION_FINISHED_EVENT_ACTION;
  payload: TierSessionFinishedEvent;
}

export interface TierStartedEventAction {
  type: EventActionTypes.TIER_STARTED_EVENT_ACTION;
  payload: TierStartedEvent;
}

export interface TierCompletedEventAction {
  type: EventActionTypes.TIER_COMPLETED_EVENT_ACTION;
  payload: TierCompletedEvent;
}

export interface TrialStartedEventAction {
  type: EventActionTypes.TRIAL_STARTED_EVENT_ACTION;
  payload: TrialStartedEvent;
}

export interface TrialLapsedEventAction {
  type: EventActionTypes.TRIAL_LAPSED_EVENT_ACTION;
  payload: TrialLapsedEvent;
}

export interface RepertoireStartedEventAction {
  type: EventActionTypes.REPERTOIRE_STARTED_EVENT_ACTION;
  payload: RepertoireStartedEvent;
}

export interface RepertoireAttemptEventAction {
  type: EventActionTypes.REPERTOIRE_ATTEMPT_EVENT_ACTION;
  payload: RepertoireAttemptEvent;
}

export interface RepertoireSessionFinishedEventAction {
  type: EventActionTypes.REPERTOIRE_SESSION_FINISHED_EVENT_ACTION;
  payload: RepertoireSessionFinishedEvent;
}

export interface RepertoirePlayingEventAction {
  type: EventActionTypes.REPERTOIRE_PLAYING_EVENT_ACTION;
  payload: RepertoirePlayingEvent;
}

export interface RepertoireCompletedEventAction {
  type: EventActionTypes.REPERTOIRE_COMPLETED_EVENT_ACTION;
  payload: RepertoireCompletedEvent;
}

export interface TutorialStartedEventAction {
  type: EventActionTypes.TUTORIAL_STARTED_EVENT_ACTION;
  payload: TutorialStartedEvent;
}

export interface TutorialCompletedEventAction {
  type: EventActionTypes.TUTORIAL_COMPLETED_EVENT_ACTION;
  payload: TutorialCompletedEvent;
}

export interface TutorialChapterStartedEventAction {
  type: EventActionTypes.TUTORIAL_CHAPTER_STARTED_EVENT_ACTION;
  payload: TutorialChapterStartedEvent;
}

export interface TutorialChapterCompletedEventAction {
  type: EventActionTypes.TUTORIAL_CHAPTER_COMPLETED_EVENT_ACTION;
  payload: TutorialChapterCompletedEvent;
}

export interface TutorialSkippedEventAction {
  type: EventActionTypes.TUTORIAL_SKIPPED_EVENT_ACTION;
  payload: TutorialSkippedEvent;
}

export interface MobileBlockedEventAction {
  type: EventActionTypes.MOBILE_BLOCKED_EVENT_ACTION;
  payload: MobileBlockedEvent;
}

export interface PhraseCompletedEventAction {
  type: EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION;
  payload: PhraseCompletedEvent;
}

export interface SafariBlockedEventAction {
  type: EventActionTypes.SAFARI_BLOCKED_EVENT_ACTION;
  payload: SafariBlockedEvent;
}

export interface SubscriptionStartedEventAction {
  type: EventActionTypes.SUBSCRIPTION_STARTED_EVENT;
}

export interface SubscriptionCanceledEventAction {
  type: EventActionTypes.SUBSCRIPTION_CANCELED_EVENT;
}

export interface SetEventQueue {
  type: EventActionTypes.SET_EVENT_QUEUE;
  payload: EventTypes[]
}

export interface DispatchEvents {
  type: EventActionTypes.DISPATCH_EVENTS
}

export interface DispatchEventsSuccess {
  type: EventActionTypes.DISPATCH_EVENTS_SUCCESS
}

export type EventStateActions = 
  | SetSessionId
  | SetEventQueue
  | DispatchEvents
  | DispatchEventsSuccess

export type EventActions =
  | SignInEventAction
  | MidiConnectionSuccessEventAction
  | MidiConnectionFailureEventAction
  | LevelPlayingEventAction
  | LevelPlayEventAction
  | LevelPauseEventAction 
  | LevelCompletedEventAction
  | LevelStartedEventAction
  | RepertoireStartedEventAction
  | RepertoirePlayingEventAction
  | RepertoireCompletedEventAction
  | TierStartedEventAction
  | TierCompletedEventAction
  | TutorialStartedEventAction
  | TutorialChapterStartedEventAction
  | TutorialSkippedEventAction
  | MobileBlockedEventAction
  | PhraseCompletedEventAction
  | SafariBlockedEventAction
  | VisitEventAction
  | PageViewEventAction
  | WelcomeLetterEventAction

export type EventTypes = 
  | AccountVerifiedEvent
  | TempoAdjustmentEvent
  | LevelPlayingEvent
  | LevelPlayEvent
  | LevelPauseEvent
  | LevelStartedEvent
  | LevelCompletedEvent
  | TierSessionFinishedEvent
  | TierStartedEvent
  | TierCompletedEvent
  | TrialStartedEvent
  | TrialLapsedEvent
  | RepertoireAttemptEvent
  | RepertoireSessionFinishedEvent
  | RepertoireStartedEvent
  | RepertoireCompletedEvent
  | RepertoirePlayingEvent
  | MidiConnectionFailureEvent
  | MidiConnectionSuccessEvent
  | MobileBlockedEvent
  | PhraseCompletedEvent 
  | SafariBlockedEvent 
  | SignInEvent 
  | SubscriptionStartedEvent
  | SubscriptionCanceledEvent
  | TutorialChapterStartedEvent 
  | TutorialChapterCompletedEvent 
  | TutorialCompletedEvent
  | TutorialSkippedEvent
  | TutorialStartedEvent 
  | VisitEvent
  | PageViewEvent
  | WelcomeLetterEvent;