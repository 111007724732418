import React from "react";
import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { IRefPhaserGame, PhaserGame } from "Phaser/PhaserGame";
import {
  ExerciseToReactEventType,
  ListenExercisePassed,
  ListenExercisePaused,
  ListenExerciseResumed,
  ListenForPhaserEvent,
  ListenHideExercise,
  ListenObjectiveComplete,
  ListenObjectiveSkipped,
  ListenShowExercise,
  ListenSkipButtonRequest,
  NotifyPhaser,
  PauseExercise,
  RemoveListener,
  ResumeExercise,
  SkipExercise,
} from "Phaser/EventBus";
import ChapterList, { getTime, Timestamp } from "../Chapters";
import "./style.css";
import Button from "@mui/material/Button";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import Fullscreen from "Components/Fullscreen";
import { markChapterAsCompleted } from "Actions/chapter";
import { useDispatch, useSelector } from "react-redux";
import { setLevelSelect } from "Actions/app";
import { useNavigate } from "react-router-dom";
import Play from "../../../assets/images/Play.png";
import Tutorial, {
  ChapterData,
  ChapterType,
  ExerciseScene,
} from "Types/ExerciseData";
import PhraseSvgProcessor, {
  PhraseObject,
  PhraseData,
} from "../SvgToPhaserPhrase";
import { PlaybackSpeedReducer } from "Reducers/videoSpeedReducer";
import { setPlaybackSpeedState } from "Actions/playback";

type Visibility = "hidden" | "visible";

const TUTORIAL_VID_URL_PREFIX =
  "https://assets.museflow.ai/videos/interactive_tutorials/";

const SkipButton = ({
  skip,
  visibility,
}: {
  skip: () => void;
  visibility?: Visibility;
}) => {
  return (
    <Button
      id="skip-button"
      className={visibility}
      sx={{
        backgroundColor: "#4A5AA1",
        borderRadius: "35px",
        width: "5%",
        minWidth: "unset",
        height: "calc(5% * 1.777778)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "absolute",
        bottom: "10%",
        opacity: 0,
        right: "10%",
        padding: "none",
        pointerEvents: "all",
      }}
      variant="contained"
      onClick={() => {
        skip();
      }}
      onKeyUp={(e) => {
        if (e.key === " ") e.preventDefault();
      }}
    >
      <FastForwardRounded sx={{ height: "100%", width: "unset" }} />
    </Button>
  );
};

const ProceedButton = ({
  proceed,
  visibility,
}: {
  proceed: () => void;
  visibility: Visibility;
}) => {
  return (
    <Box onClick={proceed} id="proceed-button">
      <Box sx={playButtonStyles} className={visibility}>
        <img
          alt="play_button"
          style={{ maxWidth: "100%", maxHeight: "100%", margin: "0px" }}
          src={Play}
        />
      </Box>
    </Box>
  );
};

const playButtonStyles = {
  position: "absolute",
  width: "70px",
  height: "70px",
  top: "calc(58%)",
  left: "calc(50% - 35px)",
  padding: "0px",
  margin: "0px",
  borderRadius: "50%",
  pointerEvents: "all",
  "&:hover": {
    background: "rgba(212,134,108,1.0)",
    cursor: "pointer",
    transition: "0.3s",
    boxShadow: "0px 0px 5px 5px rgb(212,134,108,.5)",
  },
};

// These are the layouts that will be rendered!!!
const InteractiveTemplate1 = ({ tutorialData }: { tutorialData: Tutorial }) => {
  const [lastPauseEventTime, setLastPauseEventTime] = React.useState<
    number | null
  >(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [narrationShouldPause, setNarrationShouldPause] =
    React.useState<boolean>(false);

  const [currentChapter, setCurrentChapter] = React.useState<number>(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phrasesToBeProcessed, setPhrasesToBeProcessed] = React.useState<{
    phrases: PhraseData[];
    callback: (phrases: Map<string, PhraseObject>) => void;
  } | null>(null);

  const [chapterData, setChapterData] = React.useState<ChapterData[]>();

  React.useEffect(() => {
    setChapterData(tutorialData?.sections.flatMap((x) => x.chapters));
  }, [tutorialData]);

  const phaserRef = React.useRef<IRefPhaserGame | null>(null);
  const videoRef = React.useRef<ReactPlayer>(null);

  const [exercisePaused, setExercisePaused] = React.useState(true);
  const [showExercise, setShowExercise] = React.useState(false);
  const [isExercise, setIsExercise] = React.useState(false);
  //const [playbackSpeed, setPlaybackSpeed] = React.useState(1);
  const playbackSpeed = useSelector(
    (state: PlaybackSpeedReducer) => state.playbackSpeedReducer,
  );
  const changePlaybackSpeed = React.useCallback(() => {
    dispatch(
      setPlaybackSpeedState(playbackSpeed >= 2.0 ? 0.5 : playbackSpeed + 0.25),
    );
  }, [playbackSpeed, dispatch]);
  const [skipButtonVisibility, setSkipButtonVisibility] = React.useState<
    Visibility | undefined
  >(undefined);
  const [proceedButtonVisibility, setProceedButtonVisibility] =
    React.useState<Visibility>("hidden");

  const loadScene = React.useCallback(
    (name: string, props: any) => {
      if (phaserRef.current?.scene) {
        phaserRef.current.scene.scene.start(name, {
          ...props,
        });
        console.debug("scene: ", phaserRef.current);
      } else {
        console.error("phaser scene manager not initialised!");
      }
    },
    [phaserRef],
  );

  const onSeek = () => {};

  const CheckChapter = React.useCallback(
    (chapter: number) => {
      phaserRef.current?.scene?.scene.stop();
      if (!chapterData || !tutorialData) return;
      const [type, data] = chapterData[chapter || 0]?.data;
      switch (type) {
        case ChapterType.Lecture: {
          setIsExercise(false);
          // Mark a video chapter as complete automatically after two seconds watching
          const t = setTimeout(
            () =>
              dispatch(
                markChapterAsCompleted({
                  lesson: tutorialData.level,
                  chapter: chapter || 0,
                }),
              ),
            2000,
          );
          return () => clearTimeout(t);
        }
        case ChapterType.Exercise: {
          //console.debug("loading scene");
          setIsExercise(true);
          const [name, config] = data.scene;
          if (name === ExerciseScene.StaffExercise) {
            setPhrasesToBeProcessed({
              phrases: config.objectives.map(
                ({ svgUrl, phraseId, jsonData }) => ({
                  url: svgUrl,
                  id: phraseId,
                  musicData: jsonData,
                }),
              ),
              callback: (phrases) => {
                const updatedConfig = {
                  ...config,
                  objectives: config.objectives.map((obj) => ({
                    ...obj,
                    notes: phrases.get(obj.phraseId)!.notes,
                    staff: phrases.get(obj.phraseId)!.grandStaff,
                  })),
                };
                loadScene(name, updatedConfig);
              },
            });
          } else {
            loadScene(name, config);
          }
          break;
        }
      }
    },
    [tutorialData, chapterData, dispatch, loadScene],
  );

  // Side effect triggered on focused chapter change
  React.useEffect(() => {
    CheckChapter(currentChapter);
  }, [currentChapter, CheckChapter]);

  const onProgress = React.useCallback(
    (progress: OnProgressProps) => {
      if (!chapterData) return;
      const current = progress.playedSeconds;
      if (current >= getTime(tutorialData.end)) {
        setProceedButtonVisibility("visible");
      }
      const max = chapterData
        .filter((x) => getTime(x.start) <= current)
        .reduce(
          (max, chapter, idx, arr) =>
            chapter.start > arr[max].start ? idx : max,
          0,
        );
      setCurrentChapter(max);
      const [type, data] = chapterData[max || 0]?.data;
      if (type === ChapterType.Exercise) {
        const ev = data.events.find((e) => {
          const diff = current - getTime(e.at);
          return diff <= 0.5 && diff >= 0;
        });
        //console.log("progress ", current);
        /*if (ev && getTime(ev.at) - current <= 0.5)
          console.log(current, getTime(ev.at));*/
        if (ev) {
          console.debug("event at: ", getTime(ev.at), ev, lastPauseEventTime);
          //eventThisFrame = true;
          if (lastPauseEventTime === getTime(ev?.at)) return;
          if (ev.pause) {
            setNarrationShouldPause(true);
            setIsPlaying(false);
          }
          setLastPauseEventTime(getTime(ev.at));
          if (ev.phaserEvent) NotifyPhaser(ev.phaserEvent);
        }
      }
      /*if (!eventThisFrame && current >= (lastPauseEventTime ?? 0) + 1.5)
        setLastPauseEventTime(null);*/
    },
    [lastPauseEventTime, chapterData, tutorialData.end],
  );

  const playVideo = React.useCallback(() => {
    setIsPlaying(true);
    setNarrationShouldPause(false);
  }, [setIsPlaying]);

  const resume = React.useCallback(() => {
    setIsPlaying(!narrationShouldPause);
    NotifyPhaser(ResumeExercise());
  }, [narrationShouldPause]);

  const pause = React.useCallback(() => {
    setIsPlaying(false);
    NotifyPhaser(PauseExercise());
  }, []);

  React.useEffect(() => {
    // NOTE: This is entirely dependant on the video ALWAYS resuming whenever an exercise gets unloaded
    // due to whatever reason may be (the exercise being completed succesfully / skipped or jumping
    // to a completely different chapter). Point is: if the video playback state doesn't change, this
    // breaks.
    if (isPlaying) {
      setNarrationShouldPause(false);
      setSkipButtonVisibility((prev) => {
        if (prev !== undefined) return "hidden";
        else return undefined;
      });
    }
  }, [isPlaying]);

  const onVideoClick = React.useCallback(
    (e: any) => {
      if (e.target.tagName === "VIDEO") {
        pause();
      }
    },
    [pause],
  );

  const jumpTo = React.useCallback(
    (seconds: number, chapter?: number) => {
      setProceedButtonVisibility("hidden");
      if (videoRef && videoRef.current) videoRef.current.seekTo(seconds);
      setIsPlaying(true);
      setShowExercise(false);
      //if (type === "exercise") {
      // Instead of calling this particular function manually, use another function (or effects)
      // to handle all cases of interactive sections becoming inactive / disabled.
      setExercisePaused(true);
      setLastPauseEventTime(null);
      //phaserRef.current?.scene?.scene.stop();
      if (chapter) {
        if (chapter === currentChapter) {
          CheckChapter(chapter);
        } else {
          setCurrentChapter(chapter);
        }
      }
      //NotifyPhaser();
      //}
    },
    [CheckChapter, currentChapter],
  );

  // Spacebar event listener
  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === " " || e.key === "Enter") {
        if (exercisePaused) NotifyPhaser(ResumeExercise());
        else NotifyPhaser(PauseExercise());
        if (isPlaying) setIsPlaying(false);
        else setIsPlaying(!narrationShouldPause);
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [isPlaying, narrationShouldPause, exercisePaused]);

  // Mark an exercise as complete when all of its objectives have been completed
  React.useEffect(() => {
    ListenForPhaserEvent(
      ListenExercisePassed(() => {
        dispatch(
          markChapterAsCompleted({
            lesson: tutorialData.level,
            chapter: currentChapter || 0,
          }),
        );
      }),
    );
    return () => {
      RemoveListener(ExerciseToReactEventType.ExercisePassed);
    };
  }, [currentChapter, dispatch, tutorialData.level]);

  const proceed = React.useCallback(() => {
    if (tutorialData.level === 0) {
      dispatch(setLevelSelect({ levelSelect: 1 }));
      navigate("/tutorial");
    } else {
      navigate("/lesson");
    }
  }, [dispatch, navigate, tutorialData.level]);

  // Listener for "ObjectiveComplete" events
  React.useEffect(() => {
    ListenForPhaserEvent(
      ListenObjectiveComplete(({ jumpTo }: { jumpTo: Timestamp }) => {
        videoRef.current?.seekTo(getTime(jumpTo));
        playVideo();
      }),
    );
    ListenForPhaserEvent(
      ListenObjectiveSkipped(({ jumpTo }: { jumpTo: Timestamp }) => {
        videoRef.current?.seekTo(getTime(jumpTo));
        playVideo();
      }),
    );
    return () => {
      RemoveListener(ExerciseToReactEventType.ObjectiveComplete);
      RemoveListener(ExerciseToReactEventType.ObjectiveSkipped);
    };
  }, [playVideo]);

  // Other phaser event listeners
  React.useEffect(() => {
    ListenForPhaserEvent(ListenShowExercise(() => setShowExercise(true)));
    ListenForPhaserEvent(ListenExercisePaused(() => setExercisePaused(true)));
    ListenForPhaserEvent(
      ListenHideExercise(() => {
        setExercisePaused(true);
        setShowExercise(false);
      }),
    );
    ListenForPhaserEvent(ListenExerciseResumed(() => setExercisePaused(false)));
    ListenForPhaserEvent(
      ListenSkipButtonRequest(() => setSkipButtonVisibility("visible")),
    );
    return () => {
      RemoveListener(ExerciseToReactEventType.HideExercise);
      RemoveListener(ExerciseToReactEventType.ExercisePaused);
      RemoveListener(ExerciseToReactEventType.ExerciseResumed);
      RemoveListener(ExerciseToReactEventType.ShowExercise);
      RemoveListener(ExerciseToReactEventType.SkipButtonRequest);
    };
  }, []);

  // Event listener for pausing playback when tab / window loses focus
  React.useEffect(() => {
    window.addEventListener("blur", () => {
      pause();
    });
  }, [pause]);

  const aspectRatio = 1.777778;
  // Keeps track of the <video> elements width and height which is used later for scaling the canvas
  // to fit the size of the video
  const [videoPlayerSize, setVideoPlayerSize] = React.useState({
    width: 0,
    height: 0,
  });
  const [videoLoaded, setVideoLoaded] = React.useState(false);

  const onResize = React.useCallback(
    (ratio: number) => {
      const vid: HTMLVideoElement = document.getElementsByTagName("video")[0];
      if (!vid) return;
      const height = vid.clientHeight;
      const width = vid.clientWidth;
      const desiredWidth = height * ratio;
      const desiredHeight = width / ratio;
      if (desiredWidth > width) {
        setVideoPlayerSize({ width: width, height: desiredHeight });
        console.debug(width, height, desiredWidth, desiredHeight);
      } else {
        setVideoPlayerSize({ width: desiredWidth, height: height });
        console.debug(width, height, desiredWidth, desiredHeight);
      }
    },
    [setVideoPlayerSize],
  );

  const onVideoLoad = React.useCallback(() => {
    const vid: HTMLVideoElement = document.getElementsByTagName("video")[0];
    const ratio = vid.videoWidth / vid.videoHeight;
    onResize(ratio);
    setVideoLoaded(true);
    setIsPlaying(true);
  }, [onResize]);

  // Window resize event listener
  React.useEffect(() => {
    window.addEventListener("resize", () => onResize(aspectRatio));
    return () => {
      window.removeEventListener("resize", () => onResize(aspectRatio));
    };
  }, [aspectRatio, onResize]);

  // Check if an element is within the viewport without any clipping
  const isVisible = (el: Element) => {
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  // Scroll to current chapter
  React.useEffect(() => {
    const el = document.querySelector(`#chapter-${currentChapter}`);
    if (el && !isVisible(el)) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentChapter]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        background: "linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)",
        alignItems: "top",
        justifyContent: "center",
      }}
    >
      <ChapterList
        sections={tutorialData.sections}
        jumpTo={jumpTo}
        active={currentChapter}
        lesson={tutorialData.level}
      />
      <Box
        onClick={onVideoClick}
        sx={{
          display: "flex",
          margin: "20px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <>
          {phrasesToBeProcessed && (
            <PhraseSvgProcessor
              phrases={phrasesToBeProcessed.phrases}
              callback={(stuff) => {
                setPhrasesToBeProcessed(null);
                phrasesToBeProcessed.callback(stuff);
              }}
            />
          )}
          <Box
            id="svg-processor-parent"
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <ReactPlayer
              // in order to re-pause whenever the times above are reached, the state has to stay in sync
              // with the component
              playbackRate={isExercise ? 1 : playbackSpeed}
              ref={videoRef}
              onPlay={playVideo}
              //controls={true}
              onSeek={onSeek}
              playing={isPlaying}
              type="video/mp4"
              //url="https://assets.museflow.ai/videos/unit/1/level-0.mp4"
              url={TUTORIAL_VID_URL_PREFIX + tutorialData.videoUrl}
              //url={tutorialData.videoUrl}
              onReady={onVideoLoad}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
              onProgress={onProgress}
              progressInterval={50}
              width="100%"
              height="100%"
              className="react-player"
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                pointerEvents: "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {
                <Box
                  sx={{
                    visibility: videoLoaded ? "visible" : "hidden",
                    width:
                      videoPlayerSize.width > 0 ? videoPlayerSize.width : 1280,
                    height:
                      videoPlayerSize.height > 0 ? videoPlayerSize.height : 720,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <ProceedButton
                    proceed={proceed}
                    visibility={proceedButtonVisibility}
                  />
                  <SkipButton
                    skip={() => NotifyPhaser(SkipExercise())}
                    visibility={skipButtonVisibility}
                  />
                  <Box
                    sx={{
                      zIndex: 99,
                      pointerEvents: "all",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#525969",
                        color: "white",
                        padding: "3px 10px",
                        fontFamily: "Lato",
                        fontWeight: 700,
                        border: "1px solid #525969",
                        ":hover": {
                          border: "1px solid white",
                          background: "#333B4D",
                        },
                      }}
                      onClick={changePlaybackSpeed}
                    >
                      <span>{`${playbackSpeed}x`}</span>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "10px",
                      }}
                    />
                    <Fullscreen
                      show_label={false}
                      use_dark_icon={isPlaying || !exercisePaused}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      visibility: showExercise ? "visible" : "hidden",
                    }}
                  >
                    <PhaserGame ref={phaserRef} width={1920} height={1080} />
                  </Box>
                  {!isPlaying && exercisePaused && (
                    <Box
                      onClick={resume}
                      sx={{
                        background: "#000000aa",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        pointerEvents: "all",
                      }}
                    >
                      <p style={{ fontSize: 28 }}>
                        click here, spacebar, or enter to play/pause
                      </p>
                    </Box>
                  )}
                </Box>
              }
            </Box>
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default InteractiveTemplate1;
