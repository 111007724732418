import { ReactNode } from 'react';
import { useSelector } from 'react-redux'
import { LessonReducer } from 'Reducers/lessonReducer';
import { Box, SxProps } from "@mui/system"
import { Typography } from '@mui/material'
import LeftHandIcon from 'assets/images/lh-icon.png';
import RightHandIcon from 'assets/images/rh-icon.png';
import Completion from './Completion';
import { CustomCircularProgress } from 'Components/StyledComponents';
import { totalPlayTimetoString } from 'Utils';
import { PreviousTierPlaySessions } from 'Types/LessonTypes';
import BarChart from 'Components/D3/TierWrapUp/BarChat';
import OvularNoteCountSymbol from 'Components/TierWrapUp/OvularNoteCountSymbol';
import { range } from 'lodash';
import { MediaQueries } from 'Types/MediaQueries';
import { mediaQueryValues } from 'Utils/MediaQueries';
import { useMediaQueries } from 'Hooks/useMediaQueries';
import { getColorByCbType } from 'Components/Colors';
import { ERROR_REC_CLASS } from 'Utils/Constants';
import { MainAppReducer } from 'Types';
import { CustomButton } from 'Components/StyledComponents';
import { useNavigate } from 'react-router-dom';

const Grid = ({children, mediaQueries = {
  maxHeight: mediaQueryValues.minHeight1100, 
  maxWidth: mediaQueryValues.minWidth800
}}: {
    children?: ReactNode, 
    mediaQueries?: MediaQueries
}) => {
  const gridHeightSx = (maxHeight: number) => {
    switch(maxHeight) {
      case(mediaQueryValues.minHeight1100):
        return ({
          height: '50%'    
        })
      default:
        return ({
          height: '40%'
        })
    }
  }

  const gridWidthSx = (maxWidth: number) => {
    switch(maxWidth) {
      case(mediaQueryValues.minWidth1000):
        return ({
          width: '80%'
        })
      default:
        return ({
          width: '80%'
        })
    }
  }
  
  return (
    <Box
      sx={{
        justifyContent: 'center',
        gridGap: '10px',
        display: 'grid',
        // gridAutoColumns: '3fr',
        gridTemplateColumns: '[col1-start] 1fr  [col2-start] 1fr  [col3-start] 1fr [col3-end]',
        // gridTemplateRows: '[row1-start] auto ',
        background: 'linear-gradient(240.8deg, #1D2F44 -56.75%, #071423 210.02%)',
        padding: '30px 20px',
        minHeight: '280px',
        ...gridHeightSx(mediaQueries.maxHeight),
        ...gridWidthSx(mediaQueries.maxWidth)
      }}
    >
      {children}
    </Box>
  )
}

const DataCard = ({
  children,
  gridColumn,
  gridRow = '1',
  sx
}: {
  children?: ReactNode,
  gridColumn: string,
  gridRow?: string,
  sx?: SxProps
}) => {


  return (
    <Box
      sx={{
        background: '#2E263E',
        color: '#fff',
        borderRadius: '5px',
        padding: '10px',
        textAlign: 'left',
        minWidth: '280px',
        maxWidth: '300px',
        gridColumn,
        gridRow,
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

type DivisorAlignment = 'vertical' | 'horizontal'

const DivisorLine = ({alignment='horizontal', height = '100%'}:{alignment?: DivisorAlignment, height?: string | number}) => (
  <Box
    sx={{
      boxShadow: '0 0 0 0.5px rgba(206, 208, 212, 0.5)',
      width: alignment === 'horizontal' ? height : 0,
      height: alignment === 'vertical' ? height :0
    }}
  />
)

const InfoNumber = ({children}: {children?: ReactNode}) => {
  return (
    <Typography 
      variant='h5'
      sx={{
        color: '#6F87F1',
        paddingTop: '10px',
        fontSize: '20pt',
      }}
    >
      {children}
    </Typography>
  )
}

const SubDataCard = ({children, withBackground = false, sx, mediaQueries = {
  maxHeight: mediaQueryValues.minHeight1100, 
  maxWidth: mediaQueryValues.minWidth800
}}: {
  children?: ReactNode, 
  withBackground?: boolean,
  sx?: SxProps,
  mediaQueries: MediaQueries
}) => {
  const { maxHeight, maxWidth} =  mediaQueries
  const gridHeightSx = (maxHeight: number) => {
    switch(maxHeight) {
      case(mediaQueryValues.minHeight1100):
        return ({
          height: '45%'    
        })
      default:
        return ({
          height: '45%'
        })
    }
  }

  const gridWidthSx = (minWidth: number) => {
    switch(minWidth) {
      case(mediaQueryValues.minWidth1000):
        return ({
          width: '100%'
        })
      default:
        return ({
          width: '100%'
        })
    }
  }

  return (
    <Box 
      sx={{
        background: withBackground ? '#7F81A01A' : 'none',
        marginTop: '10px',
        borderRadius: '5px',
        color: '#CED0D4',
        fontSize: '18pt',
        ...gridHeightSx(maxHeight),
        ...gridWidthSx(maxHeight),
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

const EmptyState = () => {
  return (
    <Grid>
      <CustomCircularProgress
        sx={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 'auto',
          left:0,
          right:0,
        }}
      />
      <DataCard
        gridColumn='col1-start / col2-start'
      />
      <DataCard
        gridColumn='col2-start / col3-start'
      />
      <DataCard
        gridColumn= 'col3-start'
      />
    </Grid>
  )
}

const OvularNoteCount = (
  {
    numNotes,
    color
  }: {
    numNotes: number,
    color: string
  }
) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& h3': {
          marginTop: '5px',
          marginBottom: 0,
          color: color
        }
      }}
    >
      <OvularNoteCountSymbol 
        height={30}
        fill={color}
      />
      <h3>{numNotes}</h3>
    </Box>
  )
}

type PhraseDatum = {
  datetime: string,
  averageAccuracy: string,
  averageLHAccuracy: string,
  averageRHAccuracy: string
}

type SessionData = {
  phraseData: PhraseDatum[],
  playTime: number,
  errorCount: number,
  totalOffTime: number,
  totalNotes: number
}

const testSessionData: SessionData = {
  phraseData: [
    { datetime: '2024-08-28T02:38:56.106Z', averageAccuracy: '98.02', averageLHAccuracy: '99.1', averageRHAccuracy: '97' },
    { datetime: '2024-08-28T02:39:09.459Z', averageAccuracy: '92.96', averageLHAccuracy: '85', averageRHAccuracy: '100' },
    { datetime: '2024-08-28T02:39:22.784Z', averageAccuracy: '92.34', averageLHAccuracy: '76', averageRHAccuracy: '92' },
    { datetime: '2024-08-28T02:39:36.108Z', averageAccuracy: '94.78', averageLHAccuracy: '95', averageRHAccuracy: '100' },
    { datetime: '2024-08-28T02:39:49.464Z', averageAccuracy: '97.92', averageLHAccuracy: '100', averageRHAccuracy: '97' },
    { datetime: '2024-08-28T02:40:02.780Z', averageAccuracy: '99.17', averageLHAccuracy: '98', averageRHAccuracy: '100' }
  ],
  playTime: 120013,
  errorCount: 5,
  totalOffTime: 5,
  totalNotes: 30  
}

const playSessionData = {
  "phraseData": [
    {
      "datetime": "2024-09-27T03:59:13.071Z",
      "accuracy": 95,
      "avg_lh_accuracy": 92.85,
      "avg_rh_accuracy": 100,
      "avg_running_accuracy": 100,
      "avg_lh_running_accuracy": 100,
      "avg_rh_running_accuracy": 100,
      "error_score": 0,
      "total_notes": 10,
      "bpm": 80
    },
    {
      "datetime": "2024-09-27T03:59:22.041Z",
      "accuracy": 100,
      "avg_lh_accuracy": 100,
      "avg_rh_accuracy": 100,
      "avg_running_accuracy": 100,
      "avg_lh_running_accuracy": 100,
      "avg_rh_running_accuracy": 100,
      "error_score": 0,
      "total_notes": 11,
      "bpm": 80
    },
    {
      "datetime": "2024-09-27T03:59:31.020Z",
      "accuracy": 92.85,
      "avg_lh_accuracy": 100,
      "avg_rh_accuracy": 92.3,
      "avg_running_accuracy": 100,
      "avg_lh_running_accuracy": 100,
      "avg_rh_running_accuracy": 100,
      "error_score": 0,
      "total_notes": 8,
      "bpm": 80
    },
    {
      "datetime": "2024-09-27T03:59:40.030Z",
      "accuracy": 100,
      "avg_lh_accuracy": 100,
      "avg_rh_accuracy": 100,
      "avg_running_accuracy": 100,
      "avg_lh_running_accuracy": 100,
      "avg_rh_running_accuracy": 100,
      "error_score": 0,
      "total_notes": 8,
      "bpm": 80
    }
  ],
  "id": 95,
  "user_id": "b2ae3709-cae5-4d85-b955-dde6aa697999",
  "tier_number": 2,
  "createdAt": "2024-09-27T07:58:57.108Z",
  "updatedAt": "2024-09-27T08:02:48.805Z",
  "deletedAt": null,
  "level_number": 16,
  "repertoire_id": null,
  "level_completed_event_id": 43,
  "tier_completed_event_id": 66,
  "avg_running_accuracy": 100,
  "total_notes": 37,
  "error_recognition_mode": "MIDI",
  "avg_accuracy": 96.96,
  "avg_lh_accuracy": 98.21,
  "avg_rh_accuracy": 98.07,
  "avg_lh_running_accuracy": 100,
  "avg_rh_running_accuracy": 100,
  "total_error": 0,
  "total_off_time": 0,
  "total_play_time": 45008,
  "avg_error_score": 0,
  "previousTiersPlaySessions": [
    {
      "id": 21,
      "event_type": "TierCompletedEvent",
      "user_id": "b2ae3709-cae5-4d85-b955-dde6aa697999",
      "analytics_session_id": "77ae2794-955b-427d-b703-e7cb7ed997e2",
      "datetime": "2024-09-26T05:18:11.023Z",
      "createdAt": "2024-09-26T09:18:11.057Z",
      "updatedAt": "2024-09-26T09:18:11.057Z",
      "deletedAt": null,
      "tier_number": 1,
      "level_number": 16,
      "play_session_id": 63,
      "ps_level_number": 16,
      "ps_tier_number": 1,
      "total_play_time": 90008,
      "rn": "1"
    }
  ]
}

type TierWrapUpProps = {
  mediaQueries: {
    maxHeight: mediaQueryValues.minHeight1100, 
    maxWidth: mediaQueryValues.minWidth800
  },
}

const TierWrapUp = (props: TierWrapUpProps) => {
  const {tierSummary, currentUserLevelData, currentLevelTiers, lessonProcessPending, phrasesTilNextTier} = useSelector((state: LessonReducer) => state.lessonReducer)
  const {userData} = useSelector((state: MainAppReducer) => state.mainAppReducer)

  const levelComplete = currentUserLevelData?.current_tier && currentUserLevelData?.current_tier >= currentLevelTiers
  const { mediaQueries } = props

  const { maxHeight } = useMediaQueries();
  const navigate = useNavigate();
  
  if (tierSummary || lessonProcessPending) {

    const barChartData = tierSummary?.previousTiersPlaySessions.map((tierSession: PreviousTierPlaySessions) => {
      return ({
        tier: tierSession.tier_number,
        time: tierSession.total_play_time
      })
    }).concat(
      [{
        tier: tierSummary.tier_number,
        time: tierSummary.total_play_time
      }]
    )
    .concat(
      range(tierSummary.tier_number + 1, currentLevelTiers + 1).map(curr => ({
        tier: curr,
        time: 0
      }))
    ).sort((a, b) => a.tier - b.tier) || []
    return (
      
      <>
        <Box sx={{
          marginTop: maxHeight > mediaQueryValues.minHeight900 ? '80px' : '40px',
          marginBottom: '10px',
          fontWeight: '400',
          fontStyle: 'normal',
          fontSize: '34px',
          lineHeight: '41px',
          color: '#469F6A',
          letterSpacing: '0.01em',
          fontFamily: 'Lato',
          display: 'flex',
          justifyContent: 'center'

        }}>
          {phrasesTilNextTier == 0 && 
            <>
              {levelComplete ? "Level" : `Tier ${currentUserLevelData?.current_tier} of ${currentLevelTiers}`} Complete!
            </>
          }
          {phrasesTilNextTier != 0 && 
            <>
              Session Wrap-Up
            </>
          }
          
          </Box>
        {process.env.REACT_APP_NODE_ENV !== 'prd' && <Box sx={{color:'white'}}>play session summary id: {tierSummary?.id}</Box>}
        {phrasesTilNextTier == 0 && <Completion/> }
        { tierSummary !== null && 
        <>
          <Grid
            mediaQueries = {mediaQueries}
          >
            <DataCard
              gridColumn='col1-start / col2-start'
            >
              <SubDataCard
                sx={{
                  // height: '45%',
                  paddingLeft: '20px',
                }}
                mediaQueries = {mediaQueries}
              >
                TOTAL PLAY TIME
                <InfoNumber>
                  {totalPlayTimetoString(tierSummary.total_play_time)}
                </InfoNumber>
              </SubDataCard>
              <DivisorLine/>  
              <SubDataCard
                withBackground={true}
                mediaQueries = {mediaQueries}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <BarChart
                  data={barChartData}
                />
              </SubDataCard>
            </DataCard>
            <DataCard
              gridColumn='col2-start / col3-start'
              gridRow='1'
            >
              <SubDataCard
                sx={{
                  paddingLeft: '20px'
                }}
                mediaQueries = {mediaQueries}
              > 
              AVERAGE ACCURACY
                <InfoNumber>
                  {Math.round(tierSummary.avg_running_accuracy)}%
                </InfoNumber>
              </SubDataCard>
              <DivisorLine/>  
              <SubDataCard
                withBackground={true}
                sx={{
                  height: '45%',
                }}
                mediaQueries = {mediaQueries}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '10pt'
                    }}
                  >
                    <img src={LeftHandIcon} style={{width: '45px', height: '45px'}}/>
                    LEFT
                    <InfoNumber>
                      {tierSummary?.avg_lh_running_accuracy ? Math.round(tierSummary?.avg_lh_running_accuracy) + '%' : '--'}
                    </InfoNumber>
                  </Box>
                  <DivisorLine
                    alignment='vertical'
                    height='80%'
                  />
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '10pt'
                    }}
                  >
                    <img src={RightHandIcon} style={{width: '45px', height: '45px'}}/>
                    RIGHT
                    <InfoNumber>
                    {tierSummary?.avg_rh_running_accuracy ? Math.round(tierSummary?.avg_rh_running_accuracy) + '%' : '--'}
                    </InfoNumber>
                  </Box>

                </Box>
                
              </SubDataCard>
            </DataCard>
            <DataCard
              gridColumn= 'col3-start'
            >
              <SubDataCard
                sx={{
                  paddingLeft: '20px'
                }}
                mediaQueries = {mediaQueries}
              >
                NOTES PLAYED
                <InfoNumber>
                  {
                    tierSummary.total_notes
                  }
                </InfoNumber>
              </SubDataCard>
              <DivisorLine/>  
              <SubDataCard
                withBackground={true}
                sx={{
                  height: '45%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10%',
                  width: '80%'
                }}
                mediaQueries = {mediaQueries}
              >
                <OvularNoteCount
                  numNotes={tierSummary.total_notes - tierSummary.total_error - tierSummary.total_off_time}
                  color={getColorByCbType(ERROR_REC_CLASS.CORRECT, userData?.colorblind_type || null)}
                />
              <DivisorLine
                alignment='vertical'
                height='80%'
              />
              <OvularNoteCount
                numNotes={ tierSummary.total_off_time}
                color={getColorByCbType(ERROR_REC_CLASS.SEMI_ERROR, userData?.colorblind_type || null)}
              />
              <DivisorLine
                alignment='vertical'
                height='80%'
              />
              <OvularNoteCount
                numNotes={ tierSummary.total_error}
                color={getColorByCbType(ERROR_REC_CLASS.ERROR, userData?.colorblind_type || null)}
              />
              </SubDataCard>
            </DataCard>
          </Grid>
          { phrasesTilNextTier !== 0 &&
            <CustomButton
              sx={{
                marginTop: '20px',
                width: '320px',
                height: '70px'
              }}
              onClick={()=> navigate('/roadmap')}
            >
              BACK TO ROADMAP
            </CustomButton>
          }
        </>
        }
        {
          tierSummary === null && lessonProcessPending &&
          <EmptyState/>
        }
      </>
    )
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
        <h3>
          Something went wrong retrieving your wrap up data. We're working on it (sorry). If the problem persists, please reach out to support@museflow.ai  
        </h3>
    </Box>
  )
};

export default TierWrapUp;