import {ERROR_RECOGNITION_MODE } from 'Types';
import {EventActionTypes, EventType, EventTypes, MidiData, PhraseNoteError, VisitEventAction, LevelPlayEventAction, LevelCompletedEventAction, LevelStartedEventAction, TierStartedEventAction, TierCompletedEventAction, LevelPauseEventAction, PageViewEventAction, WelcomeLetterEvent, RepertoirePlayingEvent, RepertoirePlayingEventAction, RepertoireCompletedEvent, RepertoireCompletedEventAction, WelcomeLetterEventAction, TutorialChapterStartedEvent, TutorialChapterStartedEventAction, AccountVerifiedEventAction, RepertoireSessionFinishedEvent, RepertoireSessionFinishedEventAction, RepertoireAttemptEventAction, TempoAdjustmentEventAction, TierSessionFinishedEventAction, TrialStartedEventAction, TrialLapsedEventAction, RepertoireStartedEventAction } from 'Types/EventTypes';
import {
  SetSessionId,
  SignInEventAction,
  MidiConnectionSuccessEventAction,
  MidiConnectionFailureEventAction,
  LevelPlayingEventAction,
  TutorialStartedEventAction,
  TutorialSkippedEventAction,
  MobileBlockedEventAction,
  PhraseCompletedEventAction,
  SafariBlockedEventAction,
} from 'Types/EventTypes';
import logger from 'Utils/Logger'


export type phraseCompletedEventActionPayload = {
  midiData: MidiData[],
  errorData: PhraseNoteError[],
  accuracy: number,
  avgRunningAccuracy: number,
  phraseId: string,
  tierNumber: number,
  levelNumber: number,
  errorRecognitionMode: ERROR_RECOGNITION_MODE,
}

export const setSessionId = (): SetSessionId => ({
  type: EventActionTypes.SET_SESSION_ID,
});

export const accountVerifiedAction = (): AccountVerifiedEventAction => ({
  type: EventActionTypes.ACCOUNT_VERIFIED_EVENT_ACTION,
  payload: {
    event_type: EventType.AccountVerifiedEvent,
  }
});

export const tempoAdjustmentAction = (tempo: number): TempoAdjustmentEventAction => ({
  type: EventActionTypes.TEMPO_ADJUSTMENT_EVENT_ACTION,
  payload: {
    event_type: EventType.TempoAdjustmentEvent,
    tempo
  }
});

export const visitEventAction = (): VisitEventAction => ({
  type: EventActionTypes.VISIT_EVENT_ACTION,
  payload: {
    event_type: EventType.VisitEvent,
  }
});

export const pageViewEventAction = (page: string): PageViewEventAction => ({
  type: EventActionTypes.PAGE_VIEW_EVENT_ACTION,
  payload: {
    event_type: EventType.PageViewEvent,
    page
  }
});

export const welcomeLetterEventAction = (): WelcomeLetterEventAction => ({
  type: EventActionTypes.WELCOME_LETTER_EVENT_ACTION,
  payload: {
    event_type: EventType.WelcomeLetterEvent,
  }
});

export const signInEventAction = (): SignInEventAction => ({
  type: EventActionTypes.SIGN_IN_EVENT_ACTION,
  payload: {
    event_type: EventType.SignInEvent
  }
})

export const midiConnectionSuccessEventAction = (): MidiConnectionSuccessEventAction => ({
  type: EventActionTypes.MIDI_CONNECTION_SUCCESS_EVENT_ACTION,
  payload: {
    event_type: EventType.MidiConnectionSuccessEvent
  }
});

export const midiConnectionFailureEventAction = (): MidiConnectionFailureEventAction => ({
  type: EventActionTypes.MIDI_CONNECTION_FAILURE_EVENT_ACTION,
  payload: {
    event_type: EventType.MidiConnectionFailureEvent
  }
});

export const levelPlayingEventAction = (playTime: number, levelNumber: number, playSessionId: string): LevelPlayingEventAction => ({
  type: EventActionTypes.LEVEL_PLAYING_EVENT_ACTION,
  payload: {
    event_type: EventType.LevelPlayingEvent,
    play_time: playTime,
    level_number: levelNumber,
    play_session_id: playSessionId
  }
});


export const levelPlayEventAction = (levelNumber: number,playSessionId: string): LevelPlayEventAction => ({
  type: EventActionTypes.LEVEL_PLAY_EVENT_ACTION,
  payload: {
    event_type: EventType.LevelPlayEvent,
    level_number: levelNumber,
    play_session_id: playSessionId
  }
});

export const levelPauseEventAction = (levelNumber: number, playSessionId: string): LevelPauseEventAction => ({
  type: EventActionTypes.LEVEL_PAUSE_EVENT_ACTION,
  payload: {
    event_type: EventType.LevelPauseEvent,
    level_number: levelNumber,
    play_session_id: playSessionId
  }
});

export const levelCompletedEventAction = (levelNumber: number, playSessionId: string): LevelCompletedEventAction => ({
  type: EventActionTypes.LEVEL_COMPLETED_EVENT_ACTION,
  payload: {
    event_type: EventType.LevelCompletedEvent,
    level_number: levelNumber,
    play_session_id: playSessionId
  }
});

export const levelStartedEventAction = (levelNumber: number, playSessionId: string): LevelStartedEventAction => ({
  type: EventActionTypes.LEVEL_STARTED_EVENT_ACTION,
  payload: {
    event_type: EventType.LevelStartedEvent,
    level_number: levelNumber,
    play_session_id: playSessionId
  }
});

export const tierStartedEventAction = (levelNumber: number, tierNumber: number, playSessionId: string): TierStartedEventAction => ({
  type: EventActionTypes.TIER_STARTED_EVENT_ACTION,
  payload: {
    event_type: EventType.TierStartedEvent,
    level_number: levelNumber,
    tier_number: tierNumber,
    play_session_id: playSessionId
  }
});

export const tierCompletedEventAction = (levelNumber: number, tierNumber: number, playSessionId: string): TierCompletedEventAction => ({
  type: EventActionTypes.TIER_COMPLETED_EVENT_ACTION,
  payload: {
    event_type: EventType.TierCompletedEvent,
    level_number: levelNumber,
    tier_number: tierNumber,
    play_session_id: playSessionId
  }
});

export const tierSessionFinishedEventAction = (levelNumber: number, tierNumber: number, playSessionId: string): TierSessionFinishedEventAction => ({
  type: EventActionTypes.TIER_SESSION_FINISHED_EVENT_ACTION,
  payload: {
    event_type: EventType.TierSessionFinishedEvent,
    level_number: levelNumber,
    tier_number: tierNumber,
    play_session_id: playSessionId
  }
});

export const repertoireStartedEventAction = (repertoire_id: number,playSessionId: string): RepertoireStartedEventAction => ({
  type: EventActionTypes.REPERTOIRE_STARTED_EVENT_ACTION,
  payload: {
    event_type: EventType.RepertoireStartedEvent,
    repertoire_id,
    play_session_id: playSessionId,
  }
});

export const repertoireSessionFinishedEventAction = (repertoire_id: number,playSessionId: string): RepertoireSessionFinishedEventAction => ({
  type: EventActionTypes.REPERTOIRE_SESSION_FINISHED_EVENT_ACTION,
  payload: {
    event_type: EventType.RepertoireSessionFinishedEvent,
    repertoire_id,
    play_session_id: playSessionId,
  }
});

export const repertoireAttemptEventAction = (repertoire_id: number, playSessionId: string, accuracy: number, completion: number): RepertoireAttemptEventAction => ({
  type: EventActionTypes.REPERTOIRE_ATTEMPT_EVENT_ACTION,
  payload: {
    event_type: EventType.RepertoireAttemptEvent,
    repertoire_id,
    play_session_id: playSessionId,
    accuracy,
    completion
  }
});

export const repertoirePlayingEventAction = (repertoire_id: number,playSessionId: string, playTime: number): RepertoirePlayingEventAction => ({
  type: EventActionTypes.REPERTOIRE_PLAYING_EVENT_ACTION,
  payload: {
    event_type: EventType.RepertoirePlayingEvent,
    repertoire_id,
    play_session_id: playSessionId,
    play_time: playTime
  }
});

export const repertoireCompletedEventAction = (repertoire_id: number,playSessionId: string): RepertoireCompletedEventAction => ({
  type: EventActionTypes.REPERTOIRE_COMPLETED_EVENT_ACTION,
  payload: {
    event_type: EventType.RepertoireCompletedEvent,
    repertoire_id,
    play_session_id: playSessionId
    //TODO
  }
});

export const tutorialStartedEventAction = (levelNumber: number): TutorialStartedEventAction => ({
  type: EventActionTypes.TUTORIAL_STARTED_EVENT_ACTION,
  payload: {
    event_type: EventType.TutorialStartedEvent,
    level_number: levelNumber,
  }
});

export const tutorialChapterStartedEventAction = (levelNumber: number, pageNumber: number): TutorialChapterStartedEventAction => ({
  type: EventActionTypes.TUTORIAL_CHAPTER_STARTED_EVENT_ACTION,
  payload: {
    event_type: EventType.TutorialChapterStartedEvent,
    level_number: levelNumber,
    chapter: pageNumber
  }
});

export const tutorialSkippedEventAction = (levelNumber: number, chapter: number): TutorialSkippedEventAction => ({
  type: EventActionTypes.TUTORIAL_SKIPPED_EVENT_ACTION,
  payload: {
    event_type: EventType.TutorialSkippedEvent,
    level_number: levelNumber,
    chapter
  }
});

export const mobileBlockedEventAction = (): MobileBlockedEventAction => ({
  type: EventActionTypes.MOBILE_BLOCKED_EVENT_ACTION,
  payload: {
    event_type: EventType.MobileBlockedEvent
  }
});

export const trialStartedEventAction = (): TrialStartedEventAction => ({
  type: EventActionTypes.TRIAL_STARTED_EVENT_ACTION,
  payload: {
    event_type: EventType.TrialStartedEvent
  }
});

export const trialLapsedEventAction = (): TrialLapsedEventAction => ({
  type: EventActionTypes.TRIAL_LAPSED_EVENT_ACTION,
  payload: {
    event_type: EventType.TrialLapsedEvent
  }
});

export const phraseCompletedEventAction = ({
  midiData,
  errorData,
  accuracy,
  avgLHAccuracy,
  avgRHAccuracy,
  avgRunningAccuracy,
  avgLHRunningAccuracy,
  avgRHRunningAccuracy,
  totalNotes,
  errorScore,
  phraseS3Id,
  tierNumber,
  levelNumber,
  bpm,
  errorRecognitionMode = ERROR_RECOGNITION_MODE.MIDI,
  playSessionId
}:{
  midiData: MidiData[],
  errorData: PhraseNoteError[],
  accuracy: number | null,
  avgLHAccuracy: number | null,
  avgRHAccuracy: number | null,
  avgRunningAccuracy: number | null,
  avgLHRunningAccuracy: number | null,
  avgRHRunningAccuracy: number | null,
  totalNotes: number,
  errorScore: number,
  phraseS3Id: string,
  tierNumber: number,
  levelNumber: number,
  bpm: number,
  errorRecognitionMode?: ERROR_RECOGNITION_MODE,
  playSessionId: string
}): PhraseCompletedEventAction => {
  logger.debug("added phrase completed event")
  logger.debug({
    type: EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION,
    payload: {
      event_type: EventType.PhraseCompletedEvent,
      midi_data: midiData,
      error_data: errorData,
      accuracy: accuracy,
      avg_lh_accuracy: avgLHAccuracy,
      avg_rh_accuracy: avgRHAccuracy,
      avg_running_accuracy: avgRunningAccuracy,
      avg_lh_running_accuracy: avgLHRunningAccuracy,
      avg_rh_running_accuracy: avgRHRunningAccuracy,
      total_notes: totalNotes,
      error_score: errorScore,
      phrase_s3_id: phraseS3Id,
      tier_number: tierNumber,
      level_number: levelNumber,
      error_recognition_mode: errorRecognitionMode,
      bpm: bpm,
      play_session_id: playSessionId
    }
  })
  return ({
    type: EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION,
    payload: {
      event_type: EventType.PhraseCompletedEvent,
      midi_data: midiData,
      error_data: errorData,
      accuracy: accuracy,
      avg_lh_accuracy: avgLHAccuracy,
      avg_rh_accuracy: avgRHAccuracy,
      avg_running_accuracy: avgRunningAccuracy,
      avg_lh_running_accuracy: avgLHRunningAccuracy,
      avg_rh_running_accuracy: avgRHRunningAccuracy,
      total_notes: totalNotes,
      error_score: errorScore,
      phrase_s3_id: phraseS3Id,
      tier_number: tierNumber,
      level_number: levelNumber,
      error_recognition_mode: errorRecognitionMode,
      bpm: bpm,
      play_session_id: playSessionId
    }
  });
}

export const safariBlockedEventAction = (): SafariBlockedEventAction => ({
  type: EventActionTypes.SAFARI_BLOCKED_EVENT_ACTION,
  payload: {
    event_type: EventType.SafariBlockedEvent
  }
});


export const setEventQeue = (payload: EventTypes[]) => ({
  type: EventActionTypes.SET_EVENT_QUEUE,
  payload
})

export const dispatchEvents = () => ({
  type: EventActionTypes.DISPATCH_EVENTS
})


export const dispatchEventsSuccess = () => ({
  type: EventActionTypes.DISPATCH_EVENTS_SUCCESS
})

export const flushqueue = () => ({
  type: EventActionTypes.FLUSH_QUEUE
})
